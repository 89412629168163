import { Grid, makeStyles } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { fetchById as fetchClienteById } from '../../features/cliente/clienteAPI';
import {
  documentoInstrucaoDespachoDeclaracaoImportacaoActions,
  documentoInstrucaoDespachoDeclaracaoImportacaoSelectors,
} from '../../features/documento-instrucao-despacho-declaracao-importacao/documentoInstrucaoDespachoDeclaracaoImportacaoSlice';
import { fetchById as fetchDocumentoInstrucaoDespachoById } from '../../features/documento-instrucao-despacho/documentoInstrucaoDespachoAPI';
import { selectTiposDeclaracao } from '../../features/tipo-declaracao/tipoDeclaracaoSlice';
import {
  vinculoProcessoDeclaracaoImportacaoActions,
  vinculoProcessoDeclaracaoImportacaoSelectors,
} from '../../features/vinculo-processo-declaracao-importacao/vinculoProcessoDeclaracaoImportacaoSlice';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalBondManagerOld from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXSelectClienteAutocomplete from '../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXControlBoxModeloInformacaoComplementar from '../../shared-components/modelo-informacao-complementar/QCXFinalControlBoxModeloInformacaoComplementar';
import QCXSelectCaracterizcaoOperacaoAutocomplete from '../../shared-components/select-caracterizacao-operacao/QCXSelectCaracterizcaoOperacaoAutocomplete';
import { isCaracterizacaoOperacaoContaOrdem } from '../../shared-components/select-caracterizacao-operacao/carcterizcaoOperacaoUtils';
import QCXSelectDocumentoInstrucaoAutocomplete from '../../shared-components/select-documento-instrucao-despacho/QCXSelectDocumentoInstrucaoAutocomplete';
import QCXSelectModalidadeDespachoAutocomplete from '../../shared-components/select-modalidade-despacho/QCXSelectModalidadeDespachoAutocomplete';
import QCXSelectProcessoVinculadoAutocomplete from '../../shared-components/select-processo-vinculado/QCXSelectProcessoVinculadoAutocomplete';
import { getProcessoVinculadoLabelByValue } from '../../shared-components/select-processo-vinculado/processoVinculadoUtils';
import QCXSelectTipoDeclaracaoAutocomplete from '../../shared-components/select-tipo-declaracao/QCXSelectTipoDeclaracaoAutocomplete';
import {
  TIPOS_DECLARACAO_IMPORTACAO,
  TIPO_DECLARACAO_ADMISSAO_EM_ALC_AREA_DE_LIVRE_COMERCIO,
  TIPO_DECLARACAO_ADMISSAO_EM_DAD_DEPOSITO_ADUANEIRO_DE_DISTRIBUICAO,
  TIPO_DECLARACAO_ADMISSAO_EM_DE_DAF,
  TIPO_DECLARACAO_ADMISSAO_EM_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
  TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_ADUANEIRO,
  TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_INDUSTRIAL,
  TIPO_DECLARACAO_ADMISSAO_EM_LOJA_FRANCA,
  TIPO_DECLARACAO_ADMISSAO_NA_ZFM_ZONA_FRANCA_DE_MANAUS,
  TIPO_DECLARACAO_ADMISSAO_TEMPORARIA,
  TIPO_DECLARACAO_CONSUMO,
  TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA,
  TIPO_DECLARACAO_DUIMP,
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
  TIPO_DECLARACAO_INTERNACAO_DE_ALC_AREA_DE_LIVRE_COMERCIO,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_ADMISSAO_TEMPORARIA,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_ENTREPOSTO_ADUANEIRO,
  TIPO_DECLARACAO_SAIDA_DE_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
  TIPO_DECLARACAO_SAIDA_DE_ENTREPOSTO_INDUSTRIAL,
  configureGeneralTipoDeclaracaoMatcherWith,
} from '../../shared-components/select-tipo-declaracao/tipoDeclaracaoUtils';
import QCXSelectUnidadeLocalRFBAutocomplete from '../../shared-components/select-unidade-local-rfb/QCXSelectUnidadeLocalRFBAutocomplete';
import { TRANSPORTE_ROOT_FIELD_NAMES } from '../../utils/general/declaracao-Importacao/declaracaoImportacaoUtils';
import { forceUnnormalizeNumeral, isStrictEquals } from '../../utils/general/general-utils';
import { isCalculadaStatus } from '../../utils/general/operations/operationUtils';
import TipoCalculoSeguroUtils from '../../utils/general/tipo-calculo-seguro/TipoCalculoSeguroUtils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXFormTitle from '../form-title/QCXFormTitle';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  customGridFields: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '16px',
    },
    paddingBottom: '16px',
  },
  informacoesComplementares: {
    fontFamily: 'monospace',
    overflow: 'auto !important',
    whiteSpace: 'pre !important',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0.5em',
      height: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f2f2f2',
      borderRadius: '25px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#b2b2b2f1',
      borderRadius: '25px',
    },
  },
}));

export default function QCXDeclaracaoImportacaoBasicasForm({
  initialValues,
  handleSubmit,
  isConsult,
  controlComponentProps,
  handleConditionalSteps,
  isDuimp,
  ...restProps
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  const tiposDeclaracao = useSelector(selectTiposDeclaracao);

  const modeloInformacaoComplementarFieldProps = useMemo(
    () => ({
      rootName: 'atributosAdicionais',
      meta: {
        visible: {
          informacoesComplementaresCompleta: isCalculadaStatus(initialValues?.calculada),
        },
      },
      modeloInformacaoComplementar: {
        name: 'modeloInformacaoComplementar.id',
        label: t('com.muralis.qcx.modeloInformacaoComplementar.label'),
      },
      informacoesComplementares: {
        name: 'informacoesComplementares',
        label: t('com.muralis.qcx.importador.informacoesComplementares'),
        maxLength: 7800,
        rows: 15,
        helperText: t('com.muralis.qcx.mensagem.calculoDIDUIMP'),
        inputProps: {
          className: classes.informacoesComplementares,
        },
      },
      informacoesComplementaresCompleta: {
        name: 'informacoesComplementaresCompleta',
        label: t('com.muralis.qcx.importador.informacoesComplementares'),
        maxLength: 7800,
        rows: 15,
        helperText: t('com.muralis.qcx.mensagem.calculoDIDUIMP'),
        inputProps: {
          className: classes.informacoesComplementares,
        },
      },
      referenciaCliente: {
        id: 'checkbox-field-referencia-cliente',
        key: 'checkbox-field-referencia-cliente',
        name: 'atributosAdicionais.referenciaCliente',
        label: 'Referência do Cliente',
      },
      transportadora: {
        id: 'checkbox-field-transportadora',
        key: 'checkbox-field-transportadora',
        name: 'atributosAdicionais.transportadora',
        label: t('com.muralis.qcx.transportadora.labelPlural'),
      },
      quadroResumoAdicoes: {
        id: 'checkbox-field-quadro-resumo-adicoes',
        key: 'checkbox-field-quadro-resumo-adicoes',
        name: 'atributosAdicionais.quadroResumoAdicoes',
        label: t('com.muralis.qcx.quadroResumoAdicoes'),
      },
      recintoAlfandegado: {
        id: 'checkbox-field-recinto-alfandegado',
        key: 'checkbox-field-recinto-alfandegado',
        name: 'atributosAdicionais.recintoAlfandegado',
        label: t('com.muralis.qcx.recintoAlfandegado'),
      },
      fundamentoLegalIi: {
        id: 'checkbox-field-fundamento-legal-ii',
        key: 'checkbox-field-fundamento-legal-ii',
        name: 'atributosAdicionais.fundamentoLegalIi',
        label: t('com.muralis.qcx.legislacao.fundamentoLegalII'),
      },
      fundamentoLegalIpi: {
        id: 'checkbox-field-fundamento-legal-ipi',
        key: 'checkbox-field-fundamento-legal-ipi',
        name: 'atributosAdicionais.fundamentoLegalIpi',
        label: t('com.muralis.qcx.legislacao.fundamentoLegalIPI'),
      },
      fundamentoLegalIcms: {
        id: 'checkbox-field-fundamento-legal-icms',
        key: 'checkbox-field-fundamento-legal-icms',
        name: 'atributosAdicionais.fundamentoLegalIcms',
        label: t('com.muralis.qcx.legislacao.fundamentoLegalICMS'),
      },
      despachantesAduaneiros: {
        id: 'checkbox-field-despachantes-aduaneiros',
        key: 'checkbox-field-despachantes-aduaneiros',
        name: 'atributosAdicionais.despachantesAduaneiros',
        label: t('com.muralis.qcx.despachante.despachantesAduaneiros'),
      },
    }),
    [initialValues]
  );

  const processoVinculadoReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoProcessoDeclaracaoImportacaoSelectors.selectStatus,
        selectMode: vinculoProcessoDeclaracaoImportacaoSelectors.selectMode,
        selectModel: vinculoProcessoDeclaracaoImportacaoSelectors.selectModel,
      },
      actions: {
        loading: vinculoProcessoDeclaracaoImportacaoActions.loading,
        resetStatus: vinculoProcessoDeclaracaoImportacaoActions.resetStatus,
        changeToUpdateMode: vinculoProcessoDeclaracaoImportacaoActions.changeToUpdateMode,
        changeToCreateMode: vinculoProcessoDeclaracaoImportacaoActions.changeToCreateMode,
        resetMode: vinculoProcessoDeclaracaoImportacaoActions.resetMode,
        setModel: vinculoProcessoDeclaracaoImportacaoActions.setModel,
        resetModel: vinculoProcessoDeclaracaoImportacaoActions.resetModel,
      },
    }),
    []
  );

  const processoVinculadoFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.vinculoProcesso',
      fields: [
        {
          name: 'tipo',
          label: t('com.muralis.qcx.processo').toUpperCase(),
        },
        {
          name: 'identificacao',
          label: t('com.muralis.qcx.identificacao').toUpperCase(),
        },
      ],
    }),
    []
  );

  const processoVinculadoListProps = useMemo(
    () => ({
      name: 'atributosAdicionais.vinculos',
      columns: [
        {
          field: 'tipo',
          headerName: t('com.muralis.qcx.processo'),
          headerAlign: 'left',
          align: 'left',
          flex: 160,
          valueGetter: ({ row }) => getProcessoVinculadoLabelByValue(row?.tipo) || '',
        },
        {
          field: 'identificacao',
          headerName: t('com.muralis.qcx.identificacao').toUpperCase(),
          headerAlign: 'left',
          align: 'left',
          flex: 240,
          valueGetter: ({ row }) => row?.identificacao || '',
        },
      ],
    }),
    []
  );

  const handleAddProcessoVinculado = useCallback(async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    const vinculo = {
      id: uuid(),
      identificacao: currentVinculo?.identificacao,
      tipo: currentVinculo?.tipo,
    };

    const updatedVinculos = [...currentVinculos, vinculo];

    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const handleUpdateProcessoVinculado = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      const updatedVinculo = {
        ...currentVinculo,
        identificacao: currentVinculo?.identificacao,
        tipo: currentVinculo?.tipo,
      };

      const updatedVinculos =
        currentVinculos?.map((existingVinculo) =>
          isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
        ) || currentVinculos;

      handleSuccessUpdateVinculo(updatedVinculos);
    },
    []
  );

  const handleProcessoVinculadoAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(vinculo?.tipo, currentVinculo?.tipo) &&
          isStrictEquals(vinculo?.identificacao, currentVinculo?.identificacao) &&
          !isStrictEquals(vinculo?.id, currentVinculo?.id)
      ),
    []
  );

  const documentoInstrucaoDespachoReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: documentoInstrucaoDespachoDeclaracaoImportacaoSelectors.selectStatus,
        selectMode: documentoInstrucaoDespachoDeclaracaoImportacaoSelectors.selectMode,
        selectModel: documentoInstrucaoDespachoDeclaracaoImportacaoSelectors.selectModel,
      },
      actions: {
        loading: documentoInstrucaoDespachoDeclaracaoImportacaoActions.loading,
        resetStatus: documentoInstrucaoDespachoDeclaracaoImportacaoActions.resetStatus,
        changeToUpdateMode: documentoInstrucaoDespachoDeclaracaoImportacaoActions.changeToUpdateMode,
        changeToCreateMode: documentoInstrucaoDespachoDeclaracaoImportacaoActions.changeToCreateMode,
        resetMode: documentoInstrucaoDespachoDeclaracaoImportacaoActions.resetMode,
        setModel: documentoInstrucaoDespachoDeclaracaoImportacaoActions.setModel,
        resetModel: documentoInstrucaoDespachoDeclaracaoImportacaoActions.resetModel,
      },
    }),
    []
  );

  const documentoInstrucaoDespachoFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.documentoInstrucaoDespacho',
      fields: [
        {
          name: 'documentoInstrucaoDespachoImportacao.id',
          label: t('com.muralis.qcx.documento.documentoInstrucaoDespacho').toUpperCase(),
        },
        {
          name: 'identificacao',
          label: t('com.muralis.qcx.identificacao').toUpperCase(),
        },
      ],
    }),
    []
  );

  const documentoInstrucaoDespachoListProps = useMemo(
    () => ({
      name: 'atributosAdicionais.documentosInstrucoesDespacho',
      columns: [
        {
          field: 'documentoInstrucaoDespachoImportacao.description',
          headerName: t('com.muralis.qcx.documento.documentoInstrucaoDespacho'),
          headerAlign: 'left',
          align: 'left',
          flex: 190,
          valueGetter: ({ row }) => row?.documentoInstrucaoDespachoImportacao?.description || '',
        },
        {
          field: 'identificacao',
          headerName: t('com.muralis.qcx.identificacao'),
          headerAlign: 'left',
          align: 'left',
          flex: 210,
          valueGetter: ({ row }) => row?.identificacao || '',
        },
      ],
    }),
    []
  );

  const handleAddDocumentoInstrucaoDespacho = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
      const documentoInstrucaoDespachoResponse = await fetchDocumentoInstrucaoDespachoById(
        currentVinculo?.documentoInstrucaoDespachoImportacao?.id
      );

      if (documentoInstrucaoDespachoResponse?.status === 200) {
        const foundDocumentoInstrucaoDespacho = documentoInstrucaoDespachoResponse?.data;

        const vinculo = {
          id: uuid(),
          documentoInstrucaoDespachoImportacao: foundDocumentoInstrucaoDespacho,
          identificacao: currentVinculo?.identificacao,
        };

        const updatedVinculos = [...currentVinculos, vinculo];

        handleSuccessAddVinculo(updatedVinculos);
      }
    },
    []
  );

  const handleUpdateDocumentoInstrucaoDespacho = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      const documentoInstrucaoDespachoResponse = await fetchDocumentoInstrucaoDespachoById(
        currentVinculo?.documentoInstrucaoDespachoImportacao?.id
      );

      if (documentoInstrucaoDespachoResponse?.status === 200) {
        const foundDocumentoInstrucaoDespacho = documentoInstrucaoDespachoResponse?.data;

        const updatedVinculo = {
          ...currentVinculo,
          documentoInstrucaoDespachoImportacao: foundDocumentoInstrucaoDespacho,
          identificacao: currentVinculo?.identificacao,
        };

        const updatedVinculos =
          currentVinculos?.map((existingVinculo) =>
            isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
          ) || currentVinculos;

        handleSuccessUpdateVinculo(updatedVinculos);
      }
    },
    []
  );

  const handleDocumentoInstrucaoDespachoAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(
            vinculo?.documentoInstrucaoDespachoImportacao?.id,
            currentVinculo?.documentoInstrucaoDespachoImportacao?.id
          ) &&
          isStrictEquals(vinculo?.identificacao, currentVinculo?.identificacao) &&
          !isStrictEquals(vinculo?.id, currentVinculo?.id)
      ),
    []
  );

  const tipoDeclaracao = useCallback((values) => values?.atributosAdicionais?.tipoDeclaracao?.id, []);

  const isSomeTipoDeclaracaoBy = useCallback(
    (values, codesToCompare) =>
      configureGeneralTipoDeclaracaoMatcherWith(tiposDeclaracao)(tipoDeclaracao(values), codesToCompare),
    [tiposDeclaracao, tipoDeclaracao]
  );

  const isTipoDeclaracaoRelatedFieldsVisibleBy = useCallback(
    (values) =>
      isSomeTipoDeclaracaoBy(values, [
        TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_ADUANEIRO,
        TIPO_DECLARACAO_NACIONALIZACAO_DE_ADMISSAO_TEMPORARIA,
        TIPO_DECLARACAO_NACIONALIZACAO_DE_ENTREPOSTO_ADUANEIRO,
        TIPO_DECLARACAO_SAIDA_DE_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
        TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA,
        TIPO_DECLARACAO_CONSUMO,
        TIPO_DECLARACAO_DUIMP,
      ]),
    [isSomeTipoDeclaracaoBy]
  );

  const isModalidadeDespachoVisibleBy = useCallback(
    (values) =>
      isSomeTipoDeclaracaoBy(values, [
        TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_ADUANEIRO,
        TIPO_DECLARACAO_ADMISSAO_EM_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
        TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_INDUSTRIAL,
        TIPO_DECLARACAO_ADMISSAO_TEMPORARIA,
        TIPO_DECLARACAO_ADMISSAO_NA_ZFM_ZONA_FRANCA_DE_MANAUS,
        TIPO_DECLARACAO_ADMISSAO_EM_LOJA_FRANCA,
        TIPO_DECLARACAO_ADMISSAO_EM_ALC_AREA_DE_LIVRE_COMERCIO,
        TIPO_DECLARACAO_ADMISSAO_EM_DAD_DEPOSITO_ADUANEIRO_DE_DISTRIBUICAO,
        TIPO_DECLARACAO_ADMISSAO_EM_DE_DAF,
        TIPO_DECLARACAO_NACIONALIZACAO_DE_ENTREPOSTO_ADUANEIRO,
        TIPO_DECLARACAO_SAIDA_DE_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
        TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA,
        TIPO_DECLARACAO_CONSUMO,
        TIPO_DECLARACAO_DUIMP,
      ]),
    [isSomeTipoDeclaracaoBy]
  );

  const isStepCondicional = useCallback(
    (values) =>
      isSomeTipoDeclaracaoBy(values, [
        TIPO_DECLARACAO_INTERNACAO_DE_ALC_AREA_DE_LIVRE_COMERCIO,
        TIPO_DECLARACAO_SAIDA_DE_ENTREPOSTO_INDUSTRIAL,
        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
      ]),
    [isSomeTipoDeclaracaoBy]
  );

  const configureTiposDeclaracaoImportacaoFilterPredicateWith = useCallback(() => {
    const tiposDeclaracaoImportacaoFilterPredicate = (tipo) => TIPOS_DECLARACAO_IMPORTACAO.includes(tipo?.code);

    return tiposDeclaracaoImportacaoFilterPredicate;
  }, []);

  const handleImportadorChange = useCallback(
    (form) => async (value) => {
      if (!isConsult && value) {
        const response = await fetchClienteById(value);

        if (response.status === 200) {
          const currentImportador = response?.data;

          const fillInformacoesSeguroWith = (importador) => {
            const moedaSeguroId = importador?.moeda?.id;

            const valorPercentualSeguro = forceUnnormalizeNumeral(
              importador?.valorPercentualSeguro,
              formatBrazilianNumericDecimal(7)
            );

            const calculaSeguroSobreMleFrete = TipoCalculoSeguroUtils.isCalculaSobreMleFrete(
              importador?.tipoCalculoSeguro
            );

            form.change('seguro.moeda.id', moedaSeguroId);
            form.change('seguro.valorPercentual', valorPercentualSeguro);
            form.change('seguro.calculaSobreMleFrete', calculaSeguroSobreMleFrete);
          };

          const fillDadosBancariosWith = (importador) => {
            const bancoId = importador?.contaBancariaPagamentoDespesas?.banco?.id;

            const agencia = importador?.contaBancariaPagamentoDespesas?.agencia;

            const contaCorrente = importador?.contaBancariaPagamentoDespesas?.contaCorrente;

            form.change('atributosAdicionais.dadosBancarios.banco.id', bancoId);
            form.change('atributosAdicionais.dadosBancarios.agencia', agencia);
            form.change('atributosAdicionais.dadosBancarios.conta', contaCorrente);
          };

          fillInformacoesSeguroWith(currentImportador);
          fillDadosBancariosWith(currentImportador);
        }
      }
    },
    [isConsult]
  );

  const handleCaracterizacaoOperacaoChange = useCallback(
    (form) => () => {
      if (!isConsult) {
        form.change('atributosAdicionais.adquirente', undefined);
      }
    },
    [isConsult]
  );

  const handleTipoDeclaracaoChange = useCallback(
    (form, values) => (value) => {
      if (value) {
        handleConditionalSteps(isStepCondicional(values));

        if (isStepCondicional(values)) {
          TRANSPORTE_ROOT_FIELD_NAMES.forEach((fieldName) => form.change(fieldName, undefined));
        }

        const isTipoDeclaracaoRelatedFieldsHidden = isTipoDeclaracaoRelatedFieldsVisibleBy(values);

        if (!isTipoDeclaracaoRelatedFieldsHidden) {
          form.change('atributosAdicionais.fundap', undefined);
          form.change('atributosAdicionais.adquirente', undefined);
          form.change('atributosAdicionais.caracterizacaoOperacao', undefined);
        }

        const isModalidadeDespachoHidden = isModalidadeDespachoVisibleBy(values);

        if (!isModalidadeDespachoHidden) {
          form.change('atributosAdicionais.modalidadeDespacho', undefined);
        }

        form.change('atributosAdicionais.tipoDeclaracao.id', value);

        return;
      }

      form.change('atributosAdicionais.fundap', undefined);
      form.change('atributosAdicionais.adquirente', undefined);
      form.change('atributosAdicionais.tipoDeclaracao', undefined);
      form.change('atributosAdicionais.modalidadeDespacho', undefined);
      form.change('atributosAdicionais.caracterizacaoOperacao', undefined);

      handleConditionalSteps(false);
    },
    [
      isConsult,
      handleConditionalSteps,
      isStepCondicional,
      isSomeTipoDeclaracaoBy,
      isModalidadeDespachoVisibleBy,
      isTipoDeclaracaoRelatedFieldsVisibleBy,
    ]
  );

  const isAdquirenteVisibleBy = useCallback(
    (values) =>
      isCaracterizacaoOperacaoContaOrdem(values?.atributosAdicionais?.caracterizacaoOperacao) &&
      isTipoDeclaracaoRelatedFieldsVisibleBy(values),
    [isTipoDeclaracaoRelatedFieldsVisibleBy, isCaracterizacaoOperacaoContaOrdem]
  );

  const changeListeners = useMemo(
    () => [
      {
        name: 'importador.id',
        fn: handleImportadorChange,
      },
      {
        name: 'atributosAdicionais.caracterizacaoOperacao',
        fn: handleCaracterizacaoOperacaoChange,
      },
      {
        name: 'atributosAdicionais.tipoDeclaracao.id',
        fn: handleTipoDeclaracaoChange,
      },
    ],
    [handleImportadorChange, handleCaracterizacaoOperacaoChange, handleTipoDeclaracaoChange]
  );

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onChangeListeners={changeListeners}
      controlComponentProps={controlComponentProps}
      disablePristine
      {...restProps}
    >
      {({ values }) => (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <QCXFormTitle title={t('com.muralis.qcx.fichaBasicas')} />
            </Grid>

            <Grid item xs={4}>
              <QCXFinalTextField
                id="text-field-numero-transmissao"
                key="text-field-numero-transmissao"
                label={t('com.muralis.qcx.numeroTransmissao')}
                name="atributosAdicionais.numeroTransmissao"
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={4}>
              <QCXFinalTextField
                id="text-field-numero-registro"
                key="text-field-numero-registro"
                label={t('com.muralis.qcx.NDIDUIMP')}
                name="atributosAdicionais.registro"
                disabled
              />
            </Grid>

            <Grid item xs={4}>
              <QCXFinalTextField
                id="text-field-chave-registro"
                key="text-field-chave-registro"
                label='Chave de Registro'
                name="atributosAdicionais.chaveRegistro"
                disabled
              />
            </Grid>

            <Grid item xs={4}>
              <QCXFinalDatePickerField
                id="date-picker-field-data-registro"
                key="date-picker-field-data-registro"
                label={t('com.muralis.qcx.registro')}
                name="atributosAdicionais.dataRegistroSiscomex"
                disabled
              />
            </Grid>

            <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6}>
              <QCXSelectClienteAutocomplete
                id="autocomplete-select-importador-field"
                key="autocomplete-select-importador-field"
                name="importador.id"
                label={t('com.muralis.qcx.importador.label')}
                initialValues={values}
                filter={{
                  importador: true,
                }}
                disabled
              />
            </Grid>

            <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6}>
              <QCXSelectTipoDeclaracaoAutocomplete
                id="autocomplete-select-tipo-declaracao-field"
                key="autocomplete-select-tipo-declaracao-field"
                name="atributosAdicionais.tipoDeclaracao.id"
                label={t('com.muralis.qcx.tipoDeclaracao')}
                initialValues={values}
                configureFilterPredicate={configureTiposDeclaracaoImportacaoFilterPredicateWith}
                disabled={isConsult}
                required
              />
            </Grid>

            <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6}>
              <QCXSelectUnidadeLocalRFBAutocomplete
                id="autocomplete-select-urf-despacho-field"
                key="autocomplete-select-urf-despacho-field"
                name="urfDespacho.id"
                label={t('com.muralis.qcx.fatura.URFdespacho')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>

            {isTipoDeclaracaoRelatedFieldsVisibleBy(values) && (
              <>
                <Grid item className={classes.gridFields} xs={6} sm={6} md={4} lg={4}>
                  <QCXSelectCaracterizcaoOperacaoAutocomplete
                    id="autocomplete-select-caracterizacao-operacao-field"
                    key="autocomplete-select-caracterizacao-operacao-field"
                    name="atributosAdicionais.caracterizacaoOperacao"
                    label={t('com.muralis.qcx.caracterizacaoOperacao')}
                    initialValues={values}
                    disabled={isConsult}
                  />
                </Grid>
                <Grid item className={classes.customGridFields} xs={6} sm={6} md={2} lg={2}>
                  <QCXFinalCheckboxField
                    id="checkbox-fundap-field"
                    key="checkbox-fundap-field"
                    name="atributosAdicionais.fundap"
                    label={t('com.muralis.qcx.FUNDAP')}
                    disabled={isConsult}
                  />
                </Grid>
              </>
            )}

            {isModalidadeDespachoVisibleBy(values) && (
              <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6}>
                <QCXSelectModalidadeDespachoAutocomplete
                  id="autocomplete-select-urf-despacho-field"
                  key="autocomplete-select-urf-despacho-field"
                  name="atributosAdicionais.modalidadeDespacho"
                  label={t('com.muralis.qcx.despacho.modalidadeDespacho')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
            )}

            {isAdquirenteVisibleBy(values) && (
              <Grid item className={classes.gridFields} xs={6} sm={6} md={6} lg={6}>
                <QCXSelectClienteAutocomplete
                  id="autocomplete-select-importador-field"
                  key="autocomplete-select-importador-field"
                  name="atributosAdicionais.adquirente.id"
                  label={t('com.muralis.qcx.caracterizacaoOperacao')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.processosVinculados')} />
            </Grid>
            <QCXFinalBondManagerOld
              isParentConsult={isConsult}
              listProps={processoVinculadoListProps}
              formProps={processoVinculadoFormProps}
              reducerConfig={processoVinculadoReducerConfig}
              handleAdd={handleAddProcessoVinculado}
              handleUpdate={handleUpdateProcessoVinculado}
              handleAlreadyExists={handleProcessoVinculadoAlreadyExists}
            >
              <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
                <QCXSelectProcessoVinculadoAutocomplete
                  id="autocomplete-select-processo-vinculado-field"
                  key="autocomplete-select-processo-vinculado-field"
                  name="ignorableFields.vinculoProcesso.tipo"
                  label={t('com.muralis.qcx.processo')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item className={classes.customGridFields} xs={12} sm={12} md={4} lg={4}>
                <QCXFinalTextField
                  id="text-field-processo-vinculado-identificacao"
                  key="text-field-processo-vinculado-identificacao"
                  name="ignorableFields.vinculoProcesso.identificacao"
                  label={t('com.muralis.qcx.identificacao')}
                  maxLength={20}
                  disabled={isConsult}
                />
              </Grid>
            </QCXFinalBondManagerOld>

            {!isDuimp ? (
              <>
                <Grid item xs={12}>
                  <QCXFormSubtitle title={t('com.muralis.qcx.documento.documentosIntrucaoDespacho')} />
                </Grid>
                <QCXFinalBondManagerOld
                  isParentConsult={isConsult}
                  listProps={documentoInstrucaoDespachoListProps}
                  formProps={documentoInstrucaoDespachoFormProps}
                  reducerConfig={documentoInstrucaoDespachoReducerConfig}
                  handleAdd={handleAddDocumentoInstrucaoDespacho}
                  handleUpdate={handleUpdateDocumentoInstrucaoDespacho}
                  handleAlreadyExists={handleDocumentoInstrucaoDespachoAlreadyExists}
                >
                  <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
                    <QCXSelectDocumentoInstrucaoAutocomplete
                      id="autocomplete-select-vinculo-instrucao-despacho-documento-field"
                      key="autocomplete-select-vinculo-instrucao-despacho-documento-field"
                      name="ignorableFields.documentoInstrucaoDespacho.documentoInstrucaoDespachoImportacao.id"
                      label={t('com.muralis.qcx.documento.documentoInstrucaoDespacho')}
                      initialValues={values}
                      disabled={isConsult}
                    />
                  </Grid>
                  <Grid item className={classes.customGridFields} xs={12} sm={12} md={4} lg={4}>
                    <QCXFinalTextField
                      id="text-field-vinculo-instrucao-despacho-identificacao"
                      key="text-field-vinculo-instrucao-despacho-identificacao"
                      name="ignorableFields.documentoInstrucaoDespacho.identificacao"
                      label={t('com.muralis.qcx.identificacao')}
                      maxLength={24}
                      disabled={isConsult}
                    />
                  </Grid>
                </QCXFinalBondManagerOld>
              </>
            ) : null}

            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.importador.informacoesComplementares')} />
            </Grid>
            <QCXControlBoxModeloInformacaoComplementar
              id="control-box-modelo-informacoes-complementares"
              key="control-box-modelo-informacoes-complementares"
              fieldProps={modeloInformacaoComplementarFieldProps}
              disabledFields={isConsult}
              renderMiddleFields={({ fieldProps, disabledFields }) => (
                <>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField {...fieldProps?.referenciaCliente} disabled={disabledFields} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField {...fieldProps?.transportadora} disabled={disabledFields} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField {...fieldProps?.quadroResumoAdicoes} disabled={disabledFields} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField {...fieldProps?.recintoAlfandegado} disabled={disabledFields} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField {...fieldProps?.fundamentoLegalIi} disabled={disabledFields} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField {...fieldProps?.fundamentoLegalIpi} disabled={disabledFields} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField {...fieldProps?.fundamentoLegalIcms} disabled={disabledFields} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField {...fieldProps?.despachantesAduaneiros} disabled={disabledFields} />
                  </Grid>
                </>
              )}
            />
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
