/* eslint-disable indent */
import { Grid, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QCXFormSubtitle from '../../components/form-title/QCXFormSubtitle';
import { selectRegimesTributacao } from '../../features/regime-tributacao/regimeTributacaoSlice';
import QCXFinalAlternativeCurrencyField from '../final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalPercentualField from '../final-percentual-field/QCXFinalPercentualField';
import QCXSelectRegimeTributacaoAutocomplete from '../select-regime-tributacao/QCXSelectRegimeTributacaoAutocomplete';
import QCXFinalCheckboxField from '../final-checkbox-field/QCXFinalCheckboxField';
import {
  configureSpecificRegimeTributacaoReducaoMatcher,
  configureSpecificRegimeTributacaoSuspensaoMatcher,
} from '../select-regime-tributacao/regimeTributacaoUtils';
import QCXSelectOrgaoEmissorAutocomplete from '../select-orgao-emissor/QCXSelectOrgaoEmissorAutocomplete';
import { maxValue } from '../../utils/validators/field/validator';
import QCXSelectUnidadeMedidaAutocomplete from '../select-unidade-medida/QCXSelectUnidadeMedidaAutocomplete';
import QCXSelectTipoRecipienteAutocomplete from '../select-tipo-recipiente/QCXSelectTipoRecipienteAutocomplete';
import QCXFinalNumericDecimalField from '../final-numeric-decimal-field/QCXFinalNumericDecimalField';
import {
  configureGeneralTipoDeclaracaoMatcherWith,
  TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA,
} from '../select-tipo-declaracao/tipoDeclaracaoUtils';
import QCXFinalTextField from '../final-text-field/QCXFinalTextField';
import { selectTiposDeclaracao } from '../../features/tipo-declaracao/tipoDeclaracaoSlice';
import QCXFinalDatePickerField from '../final-date-picker-field/QCXFinalDatePickerField';
import QCXSelectTipoAtoLegalAutocomplete from '../select-tipo-ato-legal/QCXSelectTipoAtoLegalAutocomplete';
import QCXSelectFundamentoLegalRegimeTributacaoIpiAutocomplete from '../select-fundamento-legal-regime-tributacao-ipi/QCXSelectFundamentoLegalRegimeTributacaoIpiAutocomplete';
import {
  selectRegimesTributacaoIpi,
  fetchByNcmAsync,
} from '../../features/regime-tributacao-fundamento-legal-ipi/regimeTributacaoIpiSlice';
import InputWithCleanButton from '../../components/declaracao-importacao/inputWithCleanButton/InputWithCleanButton'

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  ipiCheckBox: {
    marginBottom: '16px',
  },
  twin: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '16px',
    },
  },
  lastTwin: {
    paddingBottom: '16px',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '16px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '16px',
    },
  },
  lastGridFields: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '16px',
    },
  },
}));

export default function QCXIPIFormGroup({ intl, modes, submitSucceeded, parentModel, isDuimp }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = useStyles();

  const { values } = useFormState();

  useEffect(() => {
    if (values?.ncm?.id) {
      dispatch(fetchByNcmAsync(values?.ncm?.id));
    }
  }, [values]);

  const regimesTributacao = useSelector(selectRegimesTributacao);
  const tiposDeclaracao = useSelector(selectTiposDeclaracao);

  const shouldShowTableAtoLegal = useMemo(() => {
    const regime = values?.ipi?.regimeTributacao;

    return regime?.id !== 156259;
  }, [values]);

  const tipoDeclaracao = useMemo(() => parentModel?.atributosAdicionais?.tipoDeclaracao?.id, [parentModel]);

  const regimeTributacao = useMemo(() => values?.ipi?.regimeTributacao?.id, [values]);

  const ipiNaoTributavel = useMemo(() => values?.ipi?.ipiNaoTributavel, [values]);

  const isRegimeTributacaoReducaoBy = useMemo(
    () => configureSpecificRegimeTributacaoReducaoMatcher(regimesTributacao)(regimeTributacao),
    [regimeTributacao, regimesTributacao]
  );
  const isRegimeTributacaoSuspensaoBy = useMemo(
    () => configureSpecificRegimeTributacaoSuspensaoMatcher(regimesTributacao)(regimeTributacao),
    [regimeTributacao, regimesTributacao]
  );

  const isSomeTipoDeclaracaoBy = useCallback(
    (codesToCompare) => configureGeneralTipoDeclaracaoMatcherWith(tiposDeclaracao)(tipoDeclaracao, codesToCompare),
    [tiposDeclaracao, tipoDeclaracao]
  );
  const { isConsult, isSubConsult, isSubNone } = modes;

  return (
    <>
      <Grid item className={classes.ipiCheckBox} xs={12} sm={12} md={12} lg={12}>
        <InputWithCleanButton>
          <QCXFinalCheckboxField
            id="checkbox-ipi-nao-tributavel-field-ipi"
            key="checkbox-ipi-nao-tributavel-field-ipi"
            name="ipi.ipiNaoTributavel"
            label={t('com.muralis.qcx.IPInaoTributavel')}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </InputWithCleanButton>
      </Grid>
      {!ipiNaoTributavel && (
        <>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <InputWithCleanButton>
                <QCXSelectRegimeTributacaoAutocomplete
                  id="select-field-regime-tributacao-ipi"
                  key="select-field-regime-tributacao-ipi"
                  name="ipi.regimeTributacao.id"
                  label={t('com.muralis.qcx.regimeTributacao')}
                  disabled={isConsult || isSubConsult || isSubNone}
                  initialValues={values}
                />
              </InputWithCleanButton>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFinalAlternativeCurrencyField
                id="currency-valor-base-calculo-real-field-ipi"
                key="currency-valor-base-calculo-real-field-ipi"
                name="ipi.baseCalculoReal"
                label={t('com.muralis.qcx.baseCalculoReais')}
                intl={intl}
                disabled
                submitSucceeded={submitSucceeded}
                adorned
                scale={7}
              />
            </Grid>
          </Grid>
        </>
      )}

      {true && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InputWithCleanButton>
              <QCXSelectFundamentoLegalRegimeTributacaoIpiAutocomplete
                id="select-field-fundamento-legal-ipi"
                key="select-field-fundamento-legal-ipi"
                name="ipi.fundamentoLegalDuimp.id"
                label={t('com.muralis.qcx.legislacao.fundamentoLegal')}
                disabled={isConsult || isSubConsult || isSubNone}
                initialValues={values}
                isDuimp={isDuimp}
              />
            </InputWithCleanButton>
          </Grid>
        </>
      )}

      {shouldShowTableAtoLegal && true && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <QCXFormSubtitle title="Fundamento Legal do Regime de Tributação" />
          </Grid>

          <Grid item className={classes.twin} xs={12} sm={6} md={6} lg={6}>
            <InputWithCleanButton>
              <QCXSelectTipoAtoLegalAutocomplete
                id="select-field-tipo-ato-legal-ipi-fundamento"
                key="select-field-tipo-ato-legal-ipi-fundamento"
                name="ipi.tipoAtoLegalFundamentoLegal.id"
                label={t('com.muralis.qcx.atoLegal')}
                disabled={isConsult || isSubConsult || isSubNone}
              />
            </InputWithCleanButton>
          </Grid>

          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <InputWithCleanButton>
                <QCXSelectOrgaoEmissorAutocomplete
                  id="select-field-orgao-emissor-ipi-fundamento"
                  key="select-field-orgao-emissor-ipi-fundamento"
                  name="ipi.orgaoEmissorFundamentoLegal.id"
                  label={t('com.muralis.qcx.orgao.orgaoEmissor')}
                  disabled={isConsult || isSubConsult || isSubNone}
                  initialValues={values}
                />
              </InputWithCleanButton>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item xs={12} sm={6} md={6} lg={6} className={classes.gridFields}>
              <InputWithCleanButton>
                <QCXFinalTextField
                  id="text-field-numero-ato-ipi-fundamento"
                  key="text-field-numero-ato-ipi-fundamento"
                  name="ipi.numeroAtoFundamentoLegal"
                  label={t('com.muralis.qcx.numeroAto')}
                  disabled={isConsult || isSubConsult || isSubNone}
                />
              </InputWithCleanButton>
            </Grid>
            <Grid item className={classes.twin} xs={12} sm={6} md={6} lg={6}>
              <InputWithCleanButton>
                <QCXFinalDatePickerField
                  id="date-picker-ano-ex-tarifario-field-ipi-fundamento"
                  key="date-picker-ano-ex-tarifario-field-ipi-fundamento"
                  name="ipi.anoFundamentoLegal"
                  label={t('com.muralis.qcx.ano')}
                  views={['year']}
                  format="YYYY"
                  placeholder="YYYY"
                  disabled={isConsult || isSubConsult || isSubNone}
                />
              </InputWithCleanButton>
            </Grid>
          </Grid>
          <Grid item className={classes.gridFields} xs={12} sm={6} md={6} lg={6}>
            <InputWithCleanButton>
              <QCXFinalTextField
                id="text-field-numero-ipi-ipi-fundamento"
                key="text-field-numero-ipi-ipi-fundamento"
                name="ipi.numeroExFundamentoLegal"
                label="Ex"
                disabled={isConsult || isSubConsult || isSubNone}
              />
            </InputWithCleanButton>
          </Grid>
        </>
      )}

      {true ? (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <QCXFormSubtitle title={t('com.muralis.qcx.exTarifario')} />
          </Grid>
          <Grid item className={classes.gridFields} xs={12} sm={6} md={6} lg={6}>
            <InputWithCleanButton>
              <QCXFinalTextField
                id="text-field-numero-ipi-ipi"
                key="text-field-numero-ipi-ipi"
                name="ipi.numero"
                label={t('com.muralis.qcx.numero')}
                disabled={isConsult || isSubConsult || isSubNone}
              />
            </InputWithCleanButton>
          </Grid>
          <Grid item className={classes.twin} xs={12} sm={6} md={6} lg={6}>
            <InputWithCleanButton>
              <QCXSelectTipoAtoLegalAutocomplete
                id="select-field-tipo-ato-legal-ipi"
                key="select-field-tipo-ato-legal-ipi"
                name="ipi.tipoAtoLegalExTarifario.id"
                label={t('com.muralis.qcx.atoLegal')}
                disabled={isConsult || isSubConsult || isSubNone}
              />
            </InputWithCleanButton>
          </Grid>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <InputWithCleanButton>
                <QCXSelectOrgaoEmissorAutocomplete
                  id="select-field-orgao-emissor-ipi"
                  key="select-field-orgao-emissor-ipi"
                  name="ipi.orgaoEmissorAtoLegal.id"
                  label={t('com.muralis.qcx.orgao.orgaoEmissor')}
                  disabled={isConsult || isSubConsult || isSubNone}
                  initialValues={values}
                />
              </InputWithCleanButton>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item xs={12} sm={6} md={6} lg={6} className={classes.gridFields}>
              <InputWithCleanButton>
                <QCXFinalTextField
                  id="text-field-numero-ato-ipi"
                  key="text-field-numero-ato-ipi"
                  name="ipi.numeroAtoExTarifario"
                  label={t('com.muralis.qcx.numeroAto')}
                  disabled={isConsult || isSubConsult || isSubNone}
                />
              </InputWithCleanButton>
            </Grid>
            <Grid item className={classes.twin} xs={12} sm={6} md={6} lg={6}>
              <InputWithCleanButton>
                <QCXFinalDatePickerField
                  id="date-picker-ano-ex-tarifario-field-ii"
                  key="date-picker-ano-ex-tarifario-field-ii"
                  name="ipi.anoExTarifario"
                  label={t('com.muralis.qcx.ano')}
                  views={['year']}
                  format="YYYY"
                  placeholder="YYYY"
                  disabled={isConsult || isSubConsult || isSubNone}
                />
              </InputWithCleanButton>
            </Grid>
          </Grid>
        </>
      ) : null}

      {!ipiNaoTributavel && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <QCXFormSubtitle title={t('com.muralis.qcx.aliquotas')} />
          </Grid>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <InputWithCleanButton>
                <QCXFinalTextField
                  id="text-field-numero-ipi-ipi"
                  key="text-field-numero-ipi-ipi"
                  name="ipi.notaComplementarTipi"
                  label={t('com.muralis.qcx.notaComplementarTIPI')}
                  disabled={isConsult || isSubConsult || isSubNone}
                />
              </InputWithCleanButton>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item xs={12} sm={6} md={6} lg={6} className={classes.gridFields}>
              <InputWithCleanButton>
                <QCXFinalPercentualField
                  id="aliquota-ad-valorem-field-ipi"
                  key="aliquota-ad-valorem-field-ipi"
                  name="ipi.aliquota"
                  label={t('com.muralis.qcx.ADValorem')}
                  disabled={isConsult || isSubConsult || isSubNone}
                  scale={7}
                />
              </InputWithCleanButton>
            </Grid>
            {(isRegimeTributacaoReducaoBy || isRegimeTributacaoSuspensaoBy) && (
              <>
                <Grid item xs={12} sm={6} md={6} lg={6} className={classes.twin}>
                  <InputWithCleanButton>
                    <QCXFinalPercentualField
                      id="aliquota-reduzida-field-ipi"
                      key="aliquota-reduzida-field-ipi"
                      name="ipi.aliquotaReduzida"
                      label={t('com.muralis.qcx.reduzida')}
                      disabled={isConsult || isSubConsult || isSubNone}
                      scale={7}
                    />
                  </InputWithCleanButton>
                </Grid>
              </>
            )}
          </Grid>

          {true ? (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <QCXFormSubtitle title={t('com.muralis.qcx.unitarias')} />
              </Grid>
              <Grid item container xs={12} sm={6} md={6} lg={6}>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridFields}>
                  <InputWithCleanButton>
                    <QCXFinalAlternativeCurrencyField
                      id="currency-valor-unidade-medida-real-field-ipi"
                      key="currency-valor-unidade-medida-real-field-ipi"
                      name="ipi.valorUnidadeMedidaReal"
                      label={t('com.muralis.qcx.unidadeMedida.valorUnidadeMedidaReais')}
                      validate={maxValue(999999999999.99)}
                      disabled={isConsult || isSubConsult || isSubNone}
                      submitSucceeded={submitSucceeded}
                      adorned
                    />
                  </InputWithCleanButton>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <InputWithCleanButton>
                    <QCXSelectTipoRecipienteAutocomplete
                      id="autocomplete-select-tipo-recipiente-field-ipi"
                      key="autocomplete-select-tipo-recipiente-field-ipi"
                      name="ipi.tipoRecipiente.id"
                      label={t('com.muralis.qcx.tipoRecipiente')}
                      initialValues={values}
                      disabled={isConsult || isSubConsult || isSubNone}
                    />
                  </InputWithCleanButton>
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} md={6} lg={6}>
                <Grid item xs={12} sm={6} md={6} lg={6} className={classes.lastGridFields}>
                  <InputWithCleanButton>
                    <QCXSelectUnidadeMedidaAutocomplete
                      id="autocomplete-select-unidade-medida-field-ipi"
                      key="autocomplete-select-unidade-medida-field-ipi"
                      name="ipi.unidadeDeMedida.id"
                      label={t('com.muralis.qcx.unidadeMedida.label')}
                      initialValues={values}
                      disabled={isConsult || isSubConsult || isSubNone}
                    />
                  </InputWithCleanButton>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} className={classes.lastTwin}>
                  <InputWithCleanButton>
                    <QCXFinalNumericDecimalField
                      id="text-field-quanidade-na-unidade-ipi"
                      key="text-field-quanidade-na-unidade-ipi"
                      name="ipi.quantidade"
                      label={t('com.muralis.qcx.quantidadeUnidade')}
                      disabled={isConsult || isSubConsult || isSubNone}
                      scale={7}
                    />
                  </InputWithCleanButton>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <InputWithCleanButton>
                    <QCXFinalNumericDecimalField
                      id="text-field-capacidade-ipi"
                      key="text-field-capacidade-ipi"
                      name="ipi.capacidade"
                      label={t('com.muralis.qcx.capacidade')}
                      disabled={isConsult || isSubConsult || isSubNone}
                      scale={7}
                    />
                  </InputWithCleanButton>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <QCXFinalAlternativeCurrencyField
                  id="valor-devido-reais-tarifario-field-ipi"
                  key="valor-devido-reais-tarifario-field-ipi"
                  name="ipi.valorDevidoReal"
                  label={t('com.muralis.qcx.valorDevidoReais')}
                  disabled
                  adorned
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <QCXFinalAlternativeCurrencyField
                  id="valor-recolher-reais-tarifario-field-ipi"
                  key="valor-recolher-reais-tarifario-field-ipi"
                  name="ipi.valorRecolherReal"
                  label={t('com.muralis.qcx.valorRecolherReais')}
                  disabled
                  adorned
                />
              </Grid>
            </>
          ) : null}
          {isRegimeTributacaoSuspensaoBy && isSomeTipoDeclaracaoBy([TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA]) && (
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <QCXFinalAlternativeCurrencyField
                id="recolher-permanecia-field-ipi"
                key="recolher-permanecia-field-ipi"
                name="ipi.recolherPermanencia"
                label={t('com.muralis.qcx.valorSuspensoReais')}
                disabled
                adorned
              />
            </Grid>
          )}
        </>
      )}
    </>
  );
}
