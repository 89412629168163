import { saveAs } from 'file-saver';
import { CorrespondenciasListItem, ICorrelation } from '../../totvs.types';
import {
  fillAndAlignString,
  formatDateToAAAAMMDD,
  formatDateToDDMMAA,
  formatDateToDDMMAAAA,
  formatDateToMMAAAA,
  saveTxtFile,
} from '../totvsEscritaPage.helpers';
import { DanfeCapa, DanfeItem } from './totvsEscritaDanfePage.types';
import { is } from 'date-fns/locale';

export const generateDanfeTXT = (
  danfeCapa: DanfeCapa[],
  danfeItens: DanfeItem[],
  isRoche: boolean,
  unidade: string,
  fileName: string | undefined
) => {
  let danfeTXT = '';

  danfeCapa.forEach((dfCapa) => {
    const danfeCapaTXT = danfeCapa ? flattenDanfeCapa(dfCapa, isRoche, unidade, danfeItens[0]) : '';
    const danfeItensTXT = danfeItens
      .filter((danfeItem) => danfeItem.numero_nota == dfCapa.numero_nota)
      .sort((a, b) => a.numero_item - b.numero_item)
      .map((danfeItem) => flattenDanfeItem(danfeItem, isRoche, unidade ? unidade : '', dfCapa?.is_duimp))
      .join('');
    const partial = [danfeCapaTXT, danfeItensTXT].join('');
    if (danfeTXT.length > 0) danfeTXT += '\n' + partial;
    else danfeTXT += partial;
  });

  saveTxtFile(danfeTXT, 'NF' + fileName);
  return danfeTXT;
};

const flattenDanfeCapa = (danfeCapa: DanfeCapa, isRoche = false, unidade: String, primeiroItem: DanfeItem) => {
  let danfeCapaTXT = '';
  danfeCapaTXT += fillAndAlignString(danfeCapa.tipo, 1, 'left');
  danfeCapaTXT += fillAndAlignString(danfeCapa.codigo_empresa, 2, 'left');
  danfeCapaTXT += fillAndAlignString(danfeCapa.codigo_filial, 2, 'left');
  danfeCapaTXT += fillAndAlignString(danfeCapa.numero_nota.toString(), 9, 'right', false, 0, '0');
  danfeCapaTXT += fillAndAlignString(danfeCapa.filler1, 6, 'left');
  danfeCapaTXT += fillAndAlignString(Number(danfeCapa.aliquota_icms).toFixed(2), 7, 'left', true);

  if (isRoche && (unidade == 'Farma SP' || unidade == 'Farma GO')) {
    if (danfeCapa.is_filhote) {
      danfeCapaTXT += fillAndAlignString('3949' + '/FL', 10, 'left');
    } else {
      danfeCapaTXT += fillAndAlignString(danfeCapa.cfop, 10, 'left');
    }
  } else {
    danfeCapaTXT += fillAndAlignString(danfeCapa.cfop.replace('.', ''), 6, 'right');
  }

  if (isRoche && (unidade == 'RDC' || unidade == 'Dia')) {
    danfeCapaTXT += fillAndAlignString(danfeCapa.cfop, 5, 'left');
  } else {
    danfeCapaTXT += fillAndAlignString(null, 5, 'left');
  }

  danfeCapaTXT += fillAndAlignString(danfeCapa.cgc_emitente, 14, 'right');
  danfeCapaTXT += fillAndAlignString(danfeCapa.codigo_ipi, 3, 'left');
  danfeCapaTXT += fillAndAlignString(danfeCapa.referencia_cliente, 17, 'left');

  if (isRoche && (unidade == 'RDC' || unidade == 'Dia')) {
    danfeCapaTXT += fillAndAlignString(formatDateToDDMMAAAA(new Date().toDateString()), 8, 'right', false, 0, '0');
    danfeCapaTXT += fillAndAlignString(formatDateToAAAAMMDD(new Date().toDateString()), 8, 'right', false, 0, '0');
  } else {
    danfeCapaTXT += fillAndAlignString(danfeCapa.quantidade_itens, 2, 'right', false, 0, '0');
    danfeCapaTXT += fillAndAlignString(formatDateToMMAAAA(danfeCapa.data_referencia), 6, 'left');
    danfeCapaTXT += fillAndAlignString(formatDateToAAAAMMDD(danfeCapa.data_emissao), 8, 'left');
  }
  danfeCapaTXT += fillAndAlignString(formatDateToAAAAMMDD(danfeCapa.data_entrada), 8, 'left');
  danfeCapaTXT += fillAndAlignString(danfeCapa.filler2, 6, 'left');
  danfeCapaTXT += fillAndAlignString(formatDateToAAAAMMDD(danfeCapa.data_registro_di), 8, 'left');
  danfeCapaTXT += fillAndAlignString(danfeCapa.filler3, 2, 'left');
  danfeCapaTXT += fillAndAlignString('E', 1, 'left');
  danfeCapaTXT += fillAndAlignString('NFE', 3, 'left');
  danfeCapaTXT += fillAndAlignString(null, 1, 'left');
  danfeCapaTXT += fillAndAlignString(null, 1, 'left');
  danfeCapaTXT += fillAndAlignString(danfeCapa.flag_entrada, 1, 'left');

  if (danfeCapa.is_duimp) {
    danfeCapaTXT += fillAndAlignString(' ', 10, 'right');
  } else {
    if (isRoche && (unidade == 'Farma SP' || unidade == 'Farma GO')) {
      danfeCapaTXT += fillAndAlignString(
        danfeCapa.numero_registro_di.replace(/[-\/]/g, ''),
        15,
        'right',
        false,
        0,
        '0'
      );
    } else {
      danfeCapaTXT += fillAndAlignString(danfeCapa.numero_registro_di.replace(/[-\/]/g, ''), 10, 'left');
    }
  }

  if (isRoche && (unidade == 'RDC' || unidade == 'Dia')) {
    danfeCapaTXT += fillAndAlignString(danfeCapa.serie.toString(), 3, 'right', false, 0, '0');
  } else {
    danfeCapaTXT += fillAndAlignString(danfeCapa.serie.toString(), 3, 'left');
  }

  if (isRoche && (unidade == 'Farma SP' || unidade == 'Farma GO')) {
    danfeCapaTXT += fillAndAlignString(danfeCapa.codigo_cst_roche, 2, 'right', false, 0, '0', 'start');
  } else {
    danfeCapaTXT += fillAndAlignString(danfeCapa.codigo_cst, 2, 'left');
  }

  danfeCapaTXT += fillAndAlignString(danfeCapa.situacao_tributaria_e, 5, 'left');
  danfeCapaTXT += fillAndAlignString(danfeCapa.situacao_tributaria_f, 5, 'left');
  danfeCapaTXT += fillAndAlignString(danfeCapa.uf_emitente, 2, 'left');
  danfeCapaTXT += fillAndAlignString(danfeCapa.via_transporte?.slice(1, 2), 1, 'left');

  if (isRoche && unidade == 'Farma GO' && danfeCapa.is_filhote) {
    danfeCapaTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
  } else {
    danfeCapaTXT += fillAndAlignString(Number(danfeCapa.valor_frete).toFixed(2), 15, 'left', true);
  }

  if (isRoche && unidade == 'Farma GO') {
    if (danfeCapa.is_filhote) {
      danfeCapaTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
    } else {
      danfeCapaTXT += fillAndAlignString(Number(danfeCapa.valor_icms_devido).toFixed(2), 15, 'left', true);
    }
  } else {
    danfeCapaTXT += fillAndAlignString(Number(danfeCapa.valor_icms).toFixed(2), 15, 'left', true);
  }

  danfeCapaTXT += fillAndAlignString(Number(danfeCapa.valor_base_icms).toFixed(2), 15, 'left', true);

  if (isRoche && (unidade == 'Farma SP' || unidade == 'Farma GO')) {
    danfeCapaTXT += fillAndAlignString(danfeCapa.codigo_transportador, 4, 'left');
  } else {
    danfeCapaTXT += fillAndAlignString('', 4, 'left');
  }

  if (isRoche && (unidade == 'Farma SP' || unidade == 'Farma GO')) {
    danfeCapaTXT += fillAndAlignString(danfeCapa.codigo_transportador, 10, 'left', false, 0, ' ');
  } else if (isRoche && (unidade == 'RDC' || unidade == 'Dia')) {
    danfeCapaTXT += fillAndAlignString(danfeCapa.codigo_transportador, 20, 'left', false, 0, ' ');
  } else {
    danfeCapaTXT += fillAndAlignString('', 20, 'left');
  }

  danfeCapaTXT += fillAndAlignString(Number(danfeCapa.valor_seguro).toFixed(2), 15, 'left', true);

  if (isRoche && unidade == 'Farma GO' && danfeCapa.is_filhote) {
    danfeCapaTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
  } else {
    danfeCapaTXT += fillAndAlignString(Number(danfeCapa.valor_ipi).toFixed(2), 15, 'left', true);
  }

  if (isRoche && unidade == 'Farma GO' && danfeCapa.is_filhote) {
    danfeCapaTXT += fillAndAlignString(danfeCapa.valor_total_nota, 15, 'left', true);
  } else {
    danfeCapaTXT += fillAndAlignString(Number(danfeCapa.valor_total_mercadoria).toFixed(2), 15, 'left', true);
  }

  danfeCapaTXT += fillAndAlignString(danfeCapa.valor_total_nota, 15, 'left', true);

  if (isRoche && unidade == 'Farma GO' && danfeCapa.is_filhote) {
    danfeCapaTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
  } else {
    danfeCapaTXT += fillAndAlignString(danfeCapa.valor_base_ipi, 15, 'left', true);
  }

  if (isRoche && (unidade == 'RDC' || unidade == 'Dia')) {
    danfeCapaTXT += fillAndAlignString('GL', 2, 'left');
  } else {
    danfeCapaTXT += fillAndAlignString(danfeCapa.filler5, 2, 'left');
  }

  if (isRoche && (unidade == 'Farma SP' || unidade == 'Farma GO')) {
    danfeCapaTXT += fillAndAlignString(danfeCapa.codigo_exportador, 10, 'left');
  } else {
    danfeCapaTXT += fillAndAlignString(danfeCapa.codigo_exportador, 4, 'left');
  }

  danfeCapaTXT += fillAndAlignString(danfeCapa.nome_exportador, 60, 'left');

  if (isRoche && unidade === 'Farma SP') {
    danfeCapaTXT += fillAndAlignString(primeiroItem.numero_processo_easy, 17, 'left');
  } else if (isRoche && unidade === 'Farma GO') {
    danfeCapaTXT += fillAndAlignString(danfeCapa.referencia_cliente, 17, 'left');
  } else {
    danfeCapaTXT += fillAndAlignString(danfeCapa.numero_po, 17, 'left');
  }

  danfeCapaTXT += fillAndAlignString(danfeCapa.perc_reducao_base_icms?.toString() ?? null, 6, 'left', true);

  if (danfeCapa.is_duimp) {
    danfeCapaTXT += fillAndAlignString(danfeCapa.numero_duimp, 15, 'left');
    danfeCapaTXT += fillAndAlignString('0001', 4, 'left'); // versao hardcoded por enquanto
  }

  if (isRoche && (unidade == 'RDC' || unidade == 'Dia')) {
    danfeCapaTXT += fillAndAlignString(danfeCapa.local_desembaraco_roche, 20, 'left');
    danfeCapaTXT += fillAndAlignString(danfeCapa.uf_desembaraco_roche, 2, 'right', false, 0, '0');
    danfeCapaTXT += fillAndAlignString(formatDateToAAAAMMDD(danfeCapa.data_de_desembaraco), 8, 'right', false, 0, '0');
    danfeCapaTXT += fillAndAlignString(danfeCapa.codigo_exportador, 8, 'left');
  } else if (isRoche && (unidade == 'Farma SP' || unidade == 'Farma GO')) {
    danfeCapaTXT += fillAndAlignString(danfeCapa.local_desembaraco_roche, 20, 'left');
    danfeCapaTXT += fillAndAlignString(danfeCapa.uf_desembaraco_roche, 2, 'right', false, 0, '0');
    danfeCapaTXT += fillAndAlignString(formatDateToAAAAMMDD(danfeCapa.data_de_desembaraco), 8, 'right', false, 0, '0');
    danfeCapaTXT += fillAndAlignString(primeiroItem.codigo_fornecedor, 20, 'left');

    if (danfeCapa.is_filhote) {
      danfeCapaTXT += fillAndAlignString(danfeCapa.peso_liquido_roche, 15, 'left', true, 3, '0');
      danfeCapaTXT += fillAndAlignString(danfeCapa.peso_bruto_roche, 15, 'left', true, 3, '0');
    } else {
      danfeCapaTXT += fillAndAlignString(danfeCapa.peso_liquido, 15, 'left', true, 3, '0');
      danfeCapaTXT += fillAndAlignString(danfeCapa.peso_bruto, 15, 'left', true, 3, '0');
    }

    danfeCapaTXT += fillAndAlignString(danfeCapa.quantidade_volumes, 5, 'right', false, 0, '0');
    danfeCapaTXT += fillAndAlignString(danfeCapa.especie, 10, 'left');

    if (unidade == 'Farma SP' || danfeCapa.is_filhote) {
      danfeCapaTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
      danfeCapaTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
      danfeCapaTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
      danfeCapaTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
      danfeCapaTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
      danfeCapaTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
    } else {
      danfeCapaTXT += fillAndAlignString(danfeCapa.valor_sda, 15, 'left', true, 2, '0');
      danfeCapaTXT += fillAndAlignString(danfeCapa.valor_armazenagem, 15, 'left', true, 2, '0');
      danfeCapaTXT += fillAndAlignString(danfeCapa.frete_rodoviario, 15, 'left', true, 2, '0');
      danfeCapaTXT += fillAndAlignString(danfeCapa.despachante, 15, 'left', true, 2, '0');
      danfeCapaTXT += fillAndAlignString(danfeCapa.ataereo, 15, 'left', true, 2, '0');
      danfeCapaTXT += fillAndAlignString(danfeCapa.afrmm, 15, 'left', true, 2, '0');
    }

    if (isRoche && unidade == 'Farma GO' && danfeCapa.is_filhote) {
      danfeCapaTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
    } else {
      danfeCapaTXT += fillAndAlignString(danfeCapa.taxa_siscomex, 15, 'left', true, 2, '0');
    }

    if (unidade == 'Farma SP' || danfeCapa.is_filhote) {
      danfeCapaTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
      danfeCapaTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
    } else {
      danfeCapaTXT += fillAndAlignString(danfeCapa.escolta, 15, 'left', true, 2, '0');
      danfeCapaTXT += fillAndAlignString(danfeCapa.pedagio, 15, 'left', true, 2, '0');
    }

    if (danfeCapa.is_filhote) {
      danfeCapaTXT += fillAndAlignString('REMESSA PARCIAL - NOTA FILHA', 72, 'left');
    } else if (danfeCapa.regime_tributacao_code == '1') {
      const number = parseFloat(danfeCapa.aliquota_icms.replace(',', '.'));
      const formatted_icms = number.toFixed(2).replace('.', ',');

      danfeCapaTXT += fillAndAlignString(`RECOLHIDO ICMS INTEGRAL ${formatted_icms}%`, 72, 'left');
    } else if (danfeCapa.is_mae) {
      danfeCapaTXT += fillAndAlignString('PRIMEIRA REMESSA - NOTA MAE - ', 72, 'left');
      danfeCapaTXT += fillAndAlignString(
        'NOTA FISCAL DE ENTRADA EMITIDA DE ACORDO COM O TARE NR.077/2005-GSF',
        72,
        'left'
      );
    } else {
      danfeCapaTXT += fillAndAlignString('', 72, 'left');
      danfeCapaTXT += fillAndAlignString(
        'NOTA FISCAL DE ENTRADA EMITIDA DE ACORDO COM O TARE NR.077/2005-GSF',
        72,
        'left'
      );
    }

    danfeCapaTXT += fillAndAlignString(`FATURA: ${danfeCapa.numero_fatura.concat()}`, 72, 'left');
    danfeCapaTXT += fillAndAlignString(`PEDIDO: ${danfeCapa.numero_pedido.concat()}`, 72, 'left');
    danfeCapaTXT += fillAndAlignString('', 72, 'left'); // obersavacao3
    danfeCapaTXT += fillAndAlignString('', 72, 'left'); // obersavacao4
    danfeCapaTXT += fillAndAlignString('', 72, 'left'); // obersavacao5
    danfeCapaTXT += fillAndAlignString('', 72, 'left'); // obersavacao6
  }

  return danfeCapaTXT;
};

const flattenDanfeItem = (danfeItem: DanfeItem, isRoche = false, unidade: string, isDuimp: boolean) => {
  let danfeItemTXT = '\n';
  danfeItemTXT += fillAndAlignString(danfeItem.tipo, 1, 'left');
  danfeItemTXT += fillAndAlignString(danfeItem.codigo_empresa, 2, 'left');
  danfeItemTXT += fillAndAlignString(danfeItem.codigo_filial, 2, 'left');
  danfeItemTXT += fillAndAlignString(danfeItem.numero_nota?.toString() ?? null, 9, 'right', false, 0, '0');

  if (isRoche && (unidade == 'RDC' || unidade == 'Dia')) {
    danfeItemTXT += fillAndAlignString(null, 6, 'left');
  } else {
    danfeItemTXT += fillAndAlignString('', 6, 'right', false, 0, ' ');
  }

  danfeItemTXT += fillAndAlignString(danfeItem.classificacao, 10, 'left');
  danfeItemTXT += fillAndAlignString(danfeItem.codigo_produdo?.replace(/(\r\n|\n|\r)/gm, ''), 20, 'left');

  danfeItemTXT += fillAndAlignString(null, 1, 'left');
  danfeItemTXT += fillAndAlignString(null, 1, 'left');

  if (isRoche && (unidade == 'RDC' || unidade == 'Dia')) {
    danfeItemTXT += fillAndAlignString(danfeItem.numero_item?.toString() ?? null, 3, 'right', false, undefined, '0');
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.numero_item?.toString() ?? null, 2, 'right', false, undefined, '0');
  }

  if (isRoche && (unidade == 'Farma SP' || unidade == 'Farma GO')) {
    danfeItemTXT += fillAndAlignString(danfeItem.quantidade, 12, 'left', true, 5);
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.quantidade, 11, 'left', true, 4);
  }

  danfeItemTXT += fillAndAlignString(danfeItem.unidade, 3, 'left');

  if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
    danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.valor_ipi, 15, 'left', true);
  }

  if (isRoche && unidade == 'Farma GO') {
    if (danfeItem.is_filhote) {
      danfeItemTXT += fillAndAlignString(danfeItem.base_icms_por_da, 15, 'left', true, 2, '0');
    } else {
      danfeItemTXT += fillAndAlignString(danfeItem.cif_icms, 15, 'left', true);
    }
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.cif_ii, 15, 'left', true);
  }

  danfeItemTXT += fillAndAlignString(danfeItem.flag_entrada, 1, 'left');
  danfeItemTXT += fillAndAlignString(null, 11, 'right', true, 0, '0');
  danfeItemTXT += fillAndAlignString(danfeItem.descricao_produto.replace(/(\r\n|\n|\r)/gm, ''), 150, 'left');

  if (isRoche && (unidade == 'RDC' || unidade == 'Dia')) {
    danfeItemTXT += fillAndAlignString(danfeItem.base_calculo_ii, 15, 'left', true);
  } else {
    if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
      danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
    } else {
      danfeItemTXT += fillAndAlignString(danfeItem.valor_fob_reais, 15, 'left', true);
    }
  }

  if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
    danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.valor_frete_reais, 15, 'left', true);
  }

  if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
    danfeItemTXT += fillAndAlignString(danfeItem.valor_seguro_danfe_adicao, 15, 'left', true, 2, '0');
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.valor_seguro_reais, 15, 'left', true);
  }

  if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
    danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
    danfeItemTXT += fillAndAlignString('0', 5, 'left', true, 2, '0');
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.valor_ii_reais, 15, 'left', true);
    danfeItemTXT += fillAndAlignString(danfeItem.aliquota_ipi, 5, 'left', true);
  }

  if (isRoche && unidade == 'Farma SP') {
    danfeItemTXT += fillAndAlignString(danfeItem.valor_siscomex_item, 15, 'left', true);
  } else {
    if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
      danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
    } else {
      danfeItemTXT += fillAndAlignString(danfeItem.valor_despesas, 15, 'left', true);
    }
  }

  danfeItemTXT += fillAndAlignString(danfeItem.numero_processo_easy ?? null, 17, 'left');
  danfeItemTXT += fillAndAlignString(danfeItem.numero_ex ?? null, 3, 'left');
  danfeItemTXT += fillAndAlignString(danfeItem.numero_invoice ?? null, 15, 'left');

  if (isRoche && unidade == 'Farma GO') {
    if (danfeItem.is_filhote) {
      danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
    } else {
      danfeItemTXT += fillAndAlignString(danfeItem.valor_icms_devido, 15, 'left', true);
    }
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.valor_icms_recolher, 15, 'left', true);
  }

  if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
    danfeItemTXT += fillAndAlignString(danfeItem.peso_liquido_danfe_adicao, 11, 'left', true, 4);
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.peso_liquido, 11, 'left', true, 4);
  }

  if (isRoche && (unidade == 'RDC' || unidade == 'Dia')) {
    danfeItemTXT += fillAndAlignString(null, 4, 'right');
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.item_pedido_compra ?? null, 4, 'right', false, 0, '0');
  }

  if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
    danfeItemTXT += fillAndAlignString('0', 5, 'left', true, 2, '0');
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.aliquota_ii, 5, 'left', true);
  }

  danfeItemTXT += fillAndAlignString(danfeItem.numero_pedido_totvs ?? null, 17, 'left');
  danfeItemTXT += fillAndAlignString(danfeItem.totvs_li ?? null, 10, 'left');

  if (isRoche && (unidade == 'RDC' || unidade == 'Dia')) {
    danfeItemTXT += fillAndAlignString(danfeItem.serie_danfe?.toString() ?? null, 3, 'right', false, 0, '0');
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.serie_danfe?.toString() ?? null, 3, 'left');
  }

  if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
    danfeItemTXT += fillAndAlignString(danfeItem.base_icms_por_da, 15, 'left', true, 2, '0');
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.base_icms_por_item, 15, 'left', true);
  }

  if ((isRoche && unidade == 'Farma SP') || unidade == 'Farma GO') {
    if (danfeItem.is_filhote) {
      danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 2);
    } else {
      danfeItemTXT += fillAndAlignString(danfeItem.valor_fob_moeda_roche, 15, 'left', true, 7);
    }
  } else if (isRoche && (unidade == 'RDC' || unidade == 'Dia')) {
    danfeItemTXT += fillAndAlignString(danfeItem.valor_fob_reais, 15, 'left', true, 7);
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.valor_fob_moeda, 15, 'left', true, 7);
  }

  if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
    danfeItemTXT += fillAndAlignString('0', 6, 'left', true, 2, '0');
  } else if (
    isRoche &&
    (unidade == 'RDC' || unidade == 'Dia' || unidade == 'Farma GO') &&
    danfeItem.aliquota_reduzida_pis
  ) {
    danfeItemTXT += fillAndAlignString(danfeItem.aliquota_reduzida_pis, 6, 'left', true);
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.aliquota_pis, 6, 'left', true);
  }

  if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
    danfeItemTXT += fillAndAlignString('0', 9, 'left', true, 2, '0');
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.valor_quantidade_pis, 9, 'left', true);
  }

  if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
    danfeItemTXT += fillAndAlignString(danfeItem.valor_aduaneiro_item, 15, 'left', true, 2, '0');
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.base_calculo_pis, 15, 'left', true);
  }

  if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
    danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.valor_pis_real, 15, 'left', true);
  }

  if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
    danfeItemTXT += fillAndAlignString('0', 6, 'left', true, 2, '0');
  } else if (
    isRoche &&
    (unidade == 'RDC' || unidade == 'Dia' || unidade == 'Farma GO') &&
    danfeItem.aliquota_reduzida_cofins
  ) {
    danfeItemTXT += fillAndAlignString(danfeItem.aliquota_reduzida_cofins, 6, 'left', true);
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.aliquota_cofins, 6, 'left', true);
  }

  if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
    danfeItemTXT += fillAndAlignString('0', 9, 'left', true, 2, '0');
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.valor_quantidade_cofins, 9, 'left', true);
  }

  if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
    danfeItemTXT += fillAndAlignString(danfeItem.valor_aduaneiro_item, 15, 'left', true, 2, '0');
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.base_calculo_cofins, 15, 'left', true);
  }

  if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
    danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.valor_cofins_real, 15, 'left', true);
  }

  danfeItemTXT += fillAndAlignString(danfeItem.numero_adicao?.toString() ?? null, 3, 'right', false, undefined, '0');

  if (isDuimp) {
    danfeItemTXT += fillAndAlignString(
      danfeItem.sequencia_corrida_item?.toString() ?? null,
      3,
      'right',
      false,
      undefined,
      '0'
    );
  } else {
    if ((isRoche && unidade == 'Farma SP') || unidade == 'Farma GO') {
      danfeItemTXT += fillAndAlignString(danfeItem.item?.toString() ?? null, 5, 'right', false, undefined, '0');
    } else {
      danfeItemTXT += fillAndAlignString(danfeItem.item?.toString() ?? null, 3, 'right', false, undefined, '0');
    }
  }

  if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
    danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
    danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
    danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
    danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 4, '0');
  } else if (isRoche && unidade == 'Farma GO') {
    danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
    danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
    danfeItemTXT += fillAndAlignString(danfeItem.despesas_aduaneiras, 15, 'left', true, 2, '0');
    danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 4, '0');
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.valor_desconto_real, 15, 'left', true, 2);
    danfeItemTXT += fillAndAlignString(danfeItem.valor_iof, 15, 'left', true, 2);
    danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 2);
    danfeItemTXT += fillAndAlignString(danfeItem.aliquota_especifica_ipi ?? null, 15, 'left', true, 4);
  }

  if (isRoche && (unidade == 'RDC' || unidade == 'Dia' || unidade == 'Farma SP' || unidade == 'Farma GO')) {
    if (danfeItem.is_filhote) {
      danfeItemTXT += fillAndAlignString('0', 16, 'left', true, 4, '0');
      danfeItemTXT += fillAndAlignString('0', 16, 'left', true, 4, '0');
      danfeItemTXT += fillAndAlignString('0', 16, 'left', true, 4, '0');
    } else {
      danfeItemTXT += fillAndAlignString(danfeItem.quantidade_total_ipi ?? null, 16, 'left', true, 4);
      danfeItemTXT += fillAndAlignString(danfeItem.quantidade_total_pis ?? null, 16, 'left', true, 4);
      danfeItemTXT += fillAndAlignString(danfeItem.quantidade_total_cofins ?? null, 16, 'left', true, 4);
    }

    danfeItemTXT += fillAndAlignString(danfeItem.base_calculo_ii ?? null, 15, 'left', true, 2);

    if (danfeItem.is_filhote) {
      danfeItemTXT += fillAndAlignString('0', 15, 'left', true, 2, '0');
    } else {
      danfeItemTXT += fillAndAlignString(danfeItem.valor_siscomex_item ?? null, 15, 'left', true, 2);
    }

    if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
      danfeItemTXT += fillAndAlignString(null, 6, 'left', true, 2, '0');
    } else if (
      unidade == 'Farma SP' ||
      unidade == 'Farma GO' ||
      danfeItem.regime_tributacao_icms == '1' ||
      danfeItem.regime_tributacao_icms == '4'
    ) {
      danfeItemTXT += fillAndAlignString(danfeItem.aliquota_icms, 6, 'left', true, 2);
    } else {
      danfeItemTXT += fillAndAlignString(null, 6, 'left', true, 2, '0');
    }
  } else {
    danfeItemTXT += fillAndAlignString(danfeItem.quantidade_total_ipi ?? null, 11, 'left', true, 4);
    danfeItemTXT += fillAndAlignString(danfeItem.quantidade_total_pis ?? null, 11, 'left', true, 4);
    danfeItemTXT += fillAndAlignString(danfeItem.quantidade_total_cofins ?? null, 11, 'left', true, 4);
    danfeItemTXT += fillAndAlignString(danfeItem.lote ?? null, 10, 'left');
    danfeItemTXT += fillAndAlignString('31121969', 8, 'left');
    danfeItemTXT += fillAndAlignString(danfeItem.aliquota_icms, 5, 'left', true);
    danfeItemTXT += fillAndAlignString('0', 6, 'left', true);
    danfeItemTXT += fillAndAlignString(danfeItem.percentual_reducao_icms ?? null, 8, 'left', true, 4);
  }

  if ((isRoche && unidade == 'Farma SP') || unidade == 'Farma GO') {
    if (danfeItem.is_filhote) {
      danfeItemTXT += fillAndAlignString('3949' + '/FL', 10, 'left');
    } else {
      danfeItemTXT += fillAndAlignString(danfeItem.cfop, 10, 'left');
    }

    danfeItemTXT += fillAndAlignString(danfeItem.percentual_reducao_icms ?? null, 6, 'left', true, 2, '0');
    danfeItemTXT += fillAndAlignString(danfeItem.codigo_fornecedor, 10, 'left');

    if (isRoche && unidade == 'Farma GO' && danfeItem.is_filhote) {
      danfeItemTXT += fillAndAlignString(danfeItem.base_calculo_ipi_da, 15, 'left', true, 2, '0');
    } else {
      danfeItemTXT += fillAndAlignString(danfeItem.base_calculo_ipi, 15, 'left', true, 2, '0');
    }

    for (let i = 0; i < 9; i++) {
      const lote =
        danfeItem?.lotes?.length > i
          ? danfeItem.lotes[i]
          : { lote: null, quantidade: null, data_validade: null, data_fabricacao: null };

      danfeItemTXT += fillAndAlignString(lote.lote, 10, 'left');
      danfeItemTXT += fillAndAlignString(' ', 1, 'left'); // Filler lote 1

      if (danfeItem.quantidade != null && lote.quantidade != null)
        // só gerar quando viria quantidade no lote
        danfeItemTXT += fillAndAlignString(danfeItem.quantidade, 17, 'left', true, 3, '0');
      else danfeItemTXT += fillAndAlignString(' ', 17, 'left'); // Filler quantidade 1

      danfeItemTXT += fillAndAlignString(' ', 1, 'left'); // Filler quantidade 1
      danfeItemTXT += fillAndAlignString(formatDateToDDMMAAAA(lote.data_fabricacao, true), 10, 'left');
      danfeItemTXT += fillAndAlignString(' ', 1, 'left'); // Filler data fabricacao
      danfeItemTXT += fillAndAlignString(formatDateToDDMMAAAA(lote.data_validade, true), 10, 'left');
      danfeItemTXT += fillAndAlignString(' ', 1, 'left'); // Filler data validade
    }

    danfeItemTXT += fillAndAlignString(' ', 39, 'left'); // Filler posicoes
    danfeItemTXT += fillAndAlignString(danfeItem.numero_pedido, 15, 'left');
    danfeItemTXT += fillAndAlignString(danfeItem.item_pedido_compra, 6, 'right');
  }

  return danfeItemTXT;
};

export const verifyDanfeCorrelations = (
  danfeCapa: DanfeCapa[],
  danfeItens: DanfeItem[],
  correlation: CorrespondenciasListItem | undefined
) => {
  let errors: string[] = [];

  if (danfeCapa.length == 0) {
    errors.push('Nenhuma DANFE encontrada.');
    return errors;
  }

  danfeCapa.forEach((despesaCapa) => {
    const cfops = correlation?.correspondenciasGerais.filter((item) => item.tipo == 'CFOP');
    if (cfops) {
      const cfop = cfops.find((d) => d.nome.split('-')[0].trim() == despesaCapa.cfop);
      if (cfop) {
        despesaCapa.cfop = cfop.totvsId;
      } else {
        errors.push(`Correspondência de CFOP ${despesaCapa.cfop} não encontrada.`);
      }
    } else {
      errors.push(`Correspondência de CFOP ${despesaCapa.cfop} não encontrada.`);
    }

    const exportadores = correlation?.correspondenciasGerais.filter((item) => item.tipo == 'Exportador');
    if (exportadores) {
      const exportador = exportadores.find((d) => d.quickcomexId == despesaCapa.codigo_exportador);
      if (exportador) {
        despesaCapa.codigo_exportador = exportador.totvsId;
      } else {
        errors.push(`Correspondência de Exportador ${despesaCapa.codigo_exportador} não encontrada.`);
      }
    }

    const transportadores = correlation?.correspondenciasGerais.filter((item) => item.tipo == 'Transportadora');
    if (transportadores) {
      const transportador = transportadores.find((d) => d.quickcomexId == despesaCapa.codigo_transportador);
      if (transportador) {
        despesaCapa.codigo_transportador = transportador.totvsId;
      } else {
        despesaCapa.codigo_transportador = ' ';
        // errors.push(`Correspondência de Transportador ${despesaCapa.codigo_transportador} não encontrada.`);
      }
    }
  });

  danfeItens.forEach((despesaItem) => {
    const unidadesMedida = correlation?.correspondenciasGerais.filter((item) => item.tipo == 'Unidade de Medida');
    if (unidadesMedida) {
      const unidadeMedida = unidadesMedida.find((d) => d.quickcomexId == despesaItem.unidade_id);
      if (unidadeMedida) {
        despesaItem.unidade = unidadeMedida.totvsId;
      } else {
        errors.push(`Correspondência de Unidade de Medida ${despesaItem.unidade} não encontrada.`);
      }
    }

    const fabricantes = correlation?.correspondenciasGerais.filter((item) => item.tipo == 'Fabricante');
    if (fabricantes && despesaItem.codigo_fornecedor) {
      const fabricante = fabricantes.find((d) => d.quickcomexId == despesaItem.codigo_fornecedor);
      if (fabricante) {
        despesaItem.codigo_fornecedor = fabricante.totvsId;
      } else {
        // errors.push(`Correspondência de Fabricante ${despesaItem.codigo_fornecedor} não encontrada.`);
      }
    }
  });

  return errors;
};
