import {
  Box,
  Dialog,
  Grid,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useDispatch } from 'react-redux';
import QCXFinalFormDataReplicator from '../../form-data-replicator/QCXFinalFormDataReplicator';
import QCXFinalAtributoNveBondManagerItens from '../QCXFinalAtributoNveItensBondManager';
import QCXInfoAlert from '../../../shared-components/alert/QCXInfoAlert';
import QCXButton from '../../../shared-components/button/QCXButton';
import TableCellWithTooltip from '../../../shared-components/tooltip-cell/TableCellWithTooltip';
import { declaracaoImportacaoAPI } from '../../../features/declaracao-importacao/declaracaoImportacaoAPI';
import { setErrorFeedback, setSuccessFeedback } from '../../../features/feedback/feedbackSlice';

const useStyles = makeStyles((theme) => ({
  gridField: {
    paddingBottom: '16px',
  },
  checkboxGridField: {
    paddingBottom: '8px',
  },
  leftSiblingGridField: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '8px',
    },
  },
  rightSiblingGridField: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: '8px',
    },
  },
  sibling: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8px',
      marginTop: '8px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '8px',
    },
  },
}));

function RepassarAtributosNVEItens({ open, onClose, idFatura, isLoading, isConsult, isCreate, isUpdate }) {
  const dispatch = useDispatch();

  const form = useForm();
  const { values: values1 } = useFormState();
  const classes = useStyles();
  const [shouldOverwrite, setShouldOverwrite] = useState(false); // State for overwrite/add option
  const hasSetInitialValuesRef = useRef(false);
  const [replicated, setReplicated] = useState(false);

  console.log('values -> :: ', values1);

  const handleResetFormData = useCallback((formParam) => {
    formParam.restart({});
  }, []);

  const handleCloseModal = useCallback(() => {
    handleResetFormData(form);
    setShouldOverwrite(false);
    setReplicated(false);
    onClose();
  }, [form, onClose, handleResetFormData]);

  useEffect(() => {
    if (isLoading || hasSetInitialValuesRef.current) return;

    const { values } = form.getState();

    if (values?.atributos) {
      setTimeout(() => {
        hasSetInitialValuesRef.current = true;
        form.change('ignorableFields', {
          atributos: values.atributos.map((a) => ({ ...a, especificacao: {} })),
        });
      }, 250);
    }
  }, [form, isLoading]);

  const formatSelection = (item) => ({ ...item });
  const [itens, setItens] = useState([]);

  useEffect(() => {
    declaracaoImportacaoAPI.findMercadoriasForNveRepasse(idFatura).then((response) => setItens(response.data));
  }, [idFatura]);

  const tableProps = useMemo(
    () => ({
      title: 'Partnumbers',
      rowsCount: itens.length,
      columns: [
        {
          field: 'item',
          headerName: 'Item',
          headerAlign: 'center',
          align: 'center',
          width: 100,
          valueGetter: ({ row }) => row?.item || '-',
          renderCell: ({ row }) => <TableCellWithTooltip value={row?.item || '-'} />,
        },
        {
          field: 'partnumber',
          headerName: 'Partnumber',
          headerAlign: 'center',
          align: 'center',
          width: 140,
          valueGetter: ({ row }) => row?.partnumber || '-',
          renderCell: ({ row }) => <TableCellWithTooltip value={row?.partnumber || '-'} />,
        },
        {
          field: 'status',
          headerName: 'Especificação',
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          valueGetter: ({ row }) => row?.adicionais?.especificacao || '-',
          renderCell: ({ row }) => <TableCellWithTooltip value={row?.adicionais?.especificacao || '-'} />,
        },
        {
          field: 'ncm',
          headerName: 'NCM',
          headerAlign: 'center',
          align: 'center',
          width: 100,
          valueGetter: ({ row }) => row?.ncmCode || '-',
          renderCell: ({ row }) => <TableCellWithTooltip value={row?.ncmCode || '-'} />,
        },
      ],
      data: itens,
    }),
    [itens]
  );
  const atributosNveProps = useMemo(
    () => ({
      modes: { isConsult, isCreate, isUpdate },
      classes,
      listProps: {
        rootName: 'ignorableFields.atributos',
      },
    }),
    [classes, isConsult, isCreate, isUpdate]
  );

  const handleSubmit = async (data) => {
    const mercadoriasIds = data?.meta?.selected?.map((s) => s.id);

    const response = await declaracaoImportacaoAPI.updateNvesRepasse({
      mercadoriasIds,
      shouldOverwrite: data?.shouldOverwrite,
      mercadoriaAtributos: data?.ignorableFields?.atributos?.map((a) => ({ ...a, id: 0 })),
    });

    if (response.status < 300) {
      dispatch(
        setSuccessFeedback({
          message: 'Dados repassados com sucesso!',
        })
      );
    } else {
      dispatch(
        setErrorFeedback({
          message: 'Houve um erro ao repassar os dados.',
        })
      );
    }
  };

  return (
    <Dialog open={open} onClose={handleCloseModal} maxWidth="xl" fullWidth>
      <DialogTitle>Repassar Atributos NVE</DialogTitle>
      <DialogContent>
        <QCXInfoAlert>`PESQUISAR NO FORMATO: NCM | PARTNUMBER`</QCXInfoAlert>
        <Grid container sm={12}>
          <QCXFinalFormDataReplicator
            isConsult={isConsult}
            isLoading={false}
            tableProps={tableProps}
            onReset={handleResetFormData}
            replicated={replicated}
            handleSetReplicated={setReplicated}
            clearSelection={false}
            formatSelection={formatSelection}
            hideControlButtons
          >
            <>
              <Box mt={4}>
                <QCXFinalAtributoNveBondManagerItens {...atributosNveProps} />
              </Box>
            </>
          </QCXFinalFormDataReplicator>
        </Grid>
        <Box mt={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={shouldOverwrite}
                onChange={(e) => setShouldOverwrite(e.target.checked)}
                color="primary"
              />
            }
            label="Limpar atributos antigos"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box gridGap={4} mt={4} mb={4} mr={2} display="flex" flexDirection="row" justifyContent="flex-end">
          <QCXButton onClick={onClose} variant="outlined" color="primary">
            Fechar
          </QCXButton>

          <Button
            onClick={() => {
              const formData = form.getState().values;
              formData.shouldOverwrite = shouldOverwrite;
              handleSubmit(formData);

              handleResetFormData(form);
              setShouldOverwrite(false);
              setReplicated(false);
            }}
            color="primary"
          >
            Salvar
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default RepassarAtributosNVEItens;
