/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid, validate } from 'uuid';
import QCXSelectCFOPOperacoesFiscaisAutocomplete from '../../shared-components/final-autocomplete-select-cfop-operacoes-fiscais-field/QCXSelectCFOPOperacoesFiscaisAutocomplete';
import QCXFinalBondManager from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManager';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import {
  BondManagerFormProps,
  BondManagerListProps,
} from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerTypes';
import { selectRegimesTributacao } from '../../features/regime-tributacao/regimeTributacaoSlice';
import TableCellWithTooltip from '../../shared-components/tooltip-cell/TableCellWithTooltip';

type Props = {
  isParentConsult: boolean;
};

type CSTBond = {
  id: string;
  regimeTributacao: {
    id: string;
    description: string;
  };
  cfop: {
    id: string;
    detalhe: string;
  };
  cst: string;
  cstPis: string;
  cstCofins: string;
  cstIpi: string;
};

export function normalizeCSTBond(cstBond: CSTBond) {
  const isLocalId = validate(cstBond?.id);

  const normalizedCSTBond = {
    ...cstBond,
    id: isLocalId ? null : cstBond?.id,
  };

  return normalizedCSTBond;
}

export function unnormalizeCSTBond(data: CSTBond): CSTBond {
  return {
    ...data,
    id: data?.id || uuid(),
  };
}

function CSTBondManager({ isParentConsult }: Props) {
  const { t } = useTranslation();
  const { values } = useFormState();

  const regimesTributacao = useSelector(selectRegimesTributacao);

  const getRegimeTributacaoDescriptionById = (id: string) =>
    regimesTributacao?.find((r: any) => r.id === id)?.description;

  const listProps: BondManagerListProps<CSTBond> = {
    name: 'cstList',
    columns: [
      {
        field: 'regimeTributacao',
        headerName: 'Regime Tributação',
        headerAlign: 'left',
        align: 'left',
        flex: 90,
        valueGetter: ({ row }) => getRegimeTributacaoDescriptionById(row?.regimeTributacao?.id) || '-',
        renderCell: ({ row }) => (
          <TableCellWithTooltip value={getRegimeTributacaoDescriptionById(row?.regimeTributacao?.id) || '-'} />
        ),
      },
      {
        field: 'cfop',
        headerName: t('com.muralis.qcx.CFOP.label'),
        headerAlign: 'left',
        align: 'left',
        flex: 90,
        valueGetter: ({ row }) => row?.cfop?.detalhe || '-',
      },
      {
        field: 'cst',
        headerName: t('com.muralis.qcx.ICMS.CSTICMS'),
        headerAlign: 'left',
        align: 'left',
        flex: 90,
        valueGetter: ({ row }) => row?.cst || '-',
      },
      {
        field: 'cstPis',
        headerName: t('com.muralis.qcx.CST.CSTPIS'),
        headerAlign: 'left',
        align: 'left',
        flex: 90,
        valueGetter: ({ row }) => row?.cstPis || '-',
      },
      {
        field: 'cstCofins',
        headerName: t('com.muralis.qcx.CST.CSTCOFINS'),
        headerAlign: 'left',
        align: 'left',
        flex: 90,
        valueGetter: ({ row }) => row?.cstCofins || '-',
      },
      {
        field: 'cstIpi',
        headerName: t('com.muralis.qcx.CST.CSTIPI'),
        headerAlign: 'left',
        align: 'left',
        flex: 90,
        valueGetter: ({ row }) => row?.cstIpi || '-',
      },
    ],
  };

  const formProps: BondManagerFormProps = {
    rootName: 'ignorableFields',
    fields: [
      {
        name: 'regimeTributacao.id',
        label: 'Regime Tributação',
        required: true,
      },
      {
        name: 'cfop.id',
        label: 'CFOP',
        required: true,
      },
      {
        name: 'cst',
        label: 'CST/ICMS',
        required: false,
      },
      {
        name: 'cstPis',
        label: 'CST PIS',
        required: false,
      },
      {
        name: 'cstCofins',
        label: 'CST COFINS',
        required: false,
      },
      {
        name: 'cstIpi',
        label: 'CST IPI',
        required: false,
      },
    ],
  };

  const handleAddCST = useCallback(async (currentVinculo: any, currentVinculos: any, handleSuccessAddVinculo: any) => {
    const newVinculo = {
      ...currentVinculo,
      id: uuid(),
    };

    const updatedVinculos = [...currentVinculos, newVinculo];

    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const handleUpdateCST = useCallback(
    async (currentVinculo: any, currentVinculos: any, handleSuccessUpdateVinculo: any) => {
      const updatedVinculo = {
        ...currentVinculo,
      };

      const updatedVinculos =
        currentVinculos?.map((existingVinculo: any) =>
          existingVinculo?.id === updatedVinculo?.id ? updatedVinculo : existingVinculo
        ) || currentVinculos;

      handleSuccessUpdateVinculo(updatedVinculos);
    },
    []
  );

  const handleCSTAlreadyExists = useCallback((currentVinculo: any, currentVinculosList: any) => {
    const regimeTributacaoId = currentVinculo?.regimeTributacao?.id;
    const alreadyExists = currentVinculosList.some(
      (vinculo: any) => vinculo?.regimeTributacao?.id === regimeTributacaoId
    );
    return alreadyExists;
  }, []);

  return (
    <QCXFinalBondManager
      isParentConsult={isParentConsult}
      listProps={listProps}
      formProps={formProps}
      handleAdd={handleAddCST}
      handleUpdate={handleUpdateCST}
      handleAlreadyExists={handleCSTAlreadyExists}
    >
      <Grid item xs={12} sm={6} md={4}>
        <QCXSelectCFOPOperacoesFiscaisAutocomplete
          id="select-cfop"
          key="select-cfop"
          name="ignorableFields.cfop"
          label={t('com.muralis.qcx.CFOP.label')}
          disabled={isParentConsult}
          initialValues={values}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <QCXFinalNumericIntegerField
          id="text-field-cst-icms"
          key="text-field-cst-icms"
          name="ignorableFields.cst"
          label={t('com.muralis.qcx.ICMS.CSTICMS')}
          disabled={isParentConsult}
          allowNegative={false}
          maxLength={3}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <QCXFinalNumericIntegerField
          id="text-field-cst-pis"
          key="text-field-cst-pis"
          name="ignorableFields.cstPis"
          label={t('com.muralis.qcx.CST.CSTPIS')}
          disabled={isParentConsult}
          allowNegative={false}
          maxLength={2}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <QCXFinalNumericIntegerField
          id="text-field-cst-cofins"
          key="text-field-cst-cofins"
          name="ignorableFields.cstCofins"
          label={t('com.muralis.qcx.CST.CSTCOFINS')}
          disabled={isParentConsult}
          allowNegative={false}
          maxLength={2}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <QCXFinalNumericIntegerField
          id="text-field-cst-ipi"
          key="text-field-cst-ipi"
          name="ignorableFields.cstIpi"
          label={t('com.muralis.qcx.CST.CSTIPI')}
          disabled={isParentConsult}
          allowNegative={false}
          maxLength={2}
        />
      </Grid>
    </QCXFinalBondManager>
  );
}

export default CSTBondManager;
