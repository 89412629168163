import { Grid } from '@material-ui/core';
import { isArray, isEmpty, isEqual, isFunction } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import QCXFormTitle from '../../components/form-title/QCXFormTitle';
import QCXDataGridControl from '../../shared-components/data-grid-control/QCXDataGridControl';
import i18n from '../../i18n';
import DataGridControl from '../simple-consult-page/SimpleConsultPageTemplate/DataGridControl';

function QCXSimpleSelectManyPageTemplate({
  isLoading,
  isConsult,
  title = i18n.t('com.muralis.qcx.formulario'),
  tableColumns,
  tableData,
  controlButtons = [],
  hideControlButtons = false,
  selected,
  filterProp = 'code',
  filterProps = [],
  filterPropGetter,
  multipleFilter = false,
  showFullData = false,
  disableFullObject = false,
  clearSelection = false,
  handleSetSelected,
  formatSelection,
  dataGridProps = {},
  isPaginated = false,
}) {
  const [localData, setLocalData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  useEffect(() => {
    setLocalData([...tableData]);
  }, [tableData, setLocalData]);

  useEffect(() => {
    if (isArray(selected) && !isEmpty(selected)) {
      const selectedIds = selected.map((item) => item.id);

      const orderedSelectedIds = selectedIds.sort();
      const orderedSelectionModel = selectionModel.sort();

      const isChanged = !isEqual(orderedSelectedIds, orderedSelectionModel);

      if (isChanged) {
        setSelectionModel(selectedIds);
      }
    }

    if (clearSelection) {
      setSelectionModel([]);
    }
  }, [selected, clearSelection, setSelectionModel, selectionModel]);

  const formatSelectionForm = (item) => {
    if (formatSelection && isFunction(formatSelection)) {
      return formatSelection(item);
    }

    return {
      id: item?.id,
    };
  };

  const handleFilterBySelection = useCallback(
    (selection) => {
      if (disableFullObject) {
        const filtered = localData.map(formatSelectionForm).filter((item) => selection.includes(item.id));

        return filtered;
      }

      const filtered = localData.filter((item) => selection.includes(item.id));

      return filtered;
    },
    [localData, disableFullObject]
  );

  const handleOnModelChange = useCallback(
    (model) => {
      if (isArray(localData) && localData.length) {
        const filtered = handleFilterBySelection(model.selectionModel);

        handleSetSelected(filtered);
      }
    },
    [localData, handleSetSelected, handleFilterBySelection]
  );

  const isSelectedValid = useMemo(() => selected && selected.length, [selected]);

  const selectedTableData = useMemo(
    () => (isConsult && isSelectedValid ? selected : localData),
    [isConsult, selected, localData, isSelectedValid]
  );

  const rows = useMemo(
    () => (showFullData ? localData : selectedTableData),
    [showFullData, localData, selectedTableData]
  );

  return (
    <>
      <Grid item xs={12}>
        <QCXFormTitle title={title} />
      </Grid>
      <Grid
        item
        xs={12}
        direction="row"
        style={{
          marginTop: '0.7rem',
        }}
      >
        {isPaginated ? (
          <DataGridControl
            columns={tableColumns}
            rows={rows}
            loading={isLoading}
            multipleFilter={multipleFilter}
            filterProps={filterProps}
            showInactiveRows
            searchGridSize={hideControlButtons ? 12 : 9}
            buttonGridSize={hideControlButtons ? 0 : 3}
            controlButtons={controlButtons}
            selectionModel={selectionModel}
            componentsProps={{ checkbox: { disabled: isConsult } }}
            onSelectionModelChange={handleOnModelChange}
            filterableColumns={tableColumns}
            filterProp={filterProp}
            filterPropGetter={filterPropGetter}
            disableSelectionOnClick
            disableColumnSelector
            checkboxSelection
            localData={selected}
            {...dataGridProps}
          />
        ) : (
          <QCXDataGridControl
            columns={tableColumns}
            rows={rows}
            loading={isLoading}
            multipleFilter={multipleFilter}
            filterProps={filterProps}
            showInactiveRows
            searchGridSize={hideControlButtons ? 12 : 9}
            buttonGridSize={hideControlButtons ? 0 : 3}
            controlButtons={controlButtons}
            selectionModel={selectionModel}
            componentsProps={{ checkbox: { disabled: isConsult } }}
            onSelectionModelChange={handleOnModelChange}
            filterableColumns={tableColumns}
            filterProp={filterProp}
            filterPropGetter={filterPropGetter}
            disableSelectionOnClick
            disableColumnSelector
            checkboxSelection
            localData={selected}
            {...dataGridProps}
          />
        )}
      </Grid>
    </>
  );
}

export default QCXSimpleSelectManyPageTemplate;
