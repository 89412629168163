import _, { isEmpty, isFunction } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  selectRelatedReportModel,
  selectRelatedDuplicataModel,
  selectRelatedMultaModel,
  selectRelatedEmissaoIcmsModel,
  selectDeclaracoesImportacao,
  selectRelatedCalculoModel,
  selectMode,
} from '../../../../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import { clienteActions } from '../../../../../features/cliente/clienteSlice';
import { IDLE_CONTROL } from '../../../../../features/config-control';
import {
  changeToConsultMode,
  setRelatedReportModel,
  resetRelatedReportModel,
  setRelatedCalculoModel,
  setRelatedRegistroModel,
  setRelatedDuplicataModel,
  setRelatedMultaModel,
  resetRelatedMultaModel,
  failure,
  loading,
  success,
  resetRelatedEmissaoIcmsModel,
  setRelatedEmissaoIcmsModel,
  loadingDI,
  resetMode,
  updateOnList,
  changeLastHistoryModel,
} from '../../../../../features/declaracao-importacao/declaracaoImportacaoSlice';
import { ReportUtils } from '../../../../../utils/general/report/reportUtils';
import {
  AWAITING_STATUS,
  CLOSED_STATUS,
  DONE_STATUS,
  FAIL_STATUS,
  isAwaitingStatus,
  isOpenStatus,
  OPEN_STATUS,
  SUBMITTING_STATUS,
} from '../../../../../utils/hooks/form/dialog/formDialogUtils';
import {
  activateByIdFromFaturaAsync,
  atualizaDadosCargaAsync,
  calcularDisLoteAsync,
  conferirByIdFromFaturaAsync,
  diagnosticoDiByIdAsync,
  duplicarByIdFromFaturaAsync,
  fetchAllDecImportWithPaginationAsync,
  generateReportDIDiagnosticoAsync,
  generateReportDIRegistroAsync,
  inactivateByIdFromFaturaAsync,
  liberarByIdFromFaturaAsync,
  obterChavesDuimpAsync,
  reabrirByIdAsync,
  registrarDuimpByIdAsync,
  retransmitirByIdFromFaturaAsync,
  transmissaoDiagnosticoDuimpByIdAsync,
  transmissaoDuimpByIdAsync,
} from '../../../../../features/declaracao-importacao/declaracaoImportacaoThunks';
import useFormDialogSync from '../../../../../utils/hooks/form/dialog/useFormDialogSync';
import { declaracaoImportacaoAPI } from '../../../../../features/declaracao-importacao/declaracaoImportacaoAPI';
import useGenericDownloader from '../../../../../utils/hooks/downloader/useGenericDownloader';
import { setErrorFeedback, setSuccessFeedback } from '../../../../../features/feedback/feedbackSlice';
import { useHasPermission } from '../../../../../ts/common/hooks/useHasPermission/useHasPermission';
import { GERARXML, TRANSMISSAO } from '../../../../../ts/common/hooks/useHasPermission/permissionsEnum';
import useConfigurePopUp from '../../../../../ts/common/hooks/popUps/useConfigurePopUp';
import useOperationMiddleware from '../../../../../utils/hooks/operation/middleware/useOperationMiddleware';
import { TIPO_DI_DUIMP } from '../../../../../utils/general/fatura/faturaUtils';
import { followUpActions } from '../../../../../features/follow-up/followUpSlice';
import { TipoProdutoUtils } from '../../../../../shared-components/select-tipo-produto/tipoProdutoUtils';
import { followUpAPI } from '../../../../../features/follow-up/followUpAPI';
import { formatDate, normalizeData, normalizeNumeral } from '../../../../../utils/general/general-utils';
import { isNaoCalculadaStatus } from '../../../../../utils/general/operations/operationUtils';
import { faturaAPI } from '../../../../../features/fatura/faturaAPI';
import { DECLARACAO_IMPORTACAO_ENDPOINT } from '../../../../../utils/api/domain/domain-endpoint-utils';
import { isCalculateMode } from '../../../../../utils/store/store-utils';

const useDeclaracaoImportacaoActionsMenu = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [exibirModalTransmissaoDuimp, setExibirModalTransmissaoDuimp] = useState(false);
  const [modelDuimpSelecionada, setModelDuimpSelecionada] = useState({});

  const canGenerateDIDiag = useHasPermission(GERARXML.DIAGNOSTICODI);
  const canGenerateTransmissaoDiagnosticoDI = useHasPermission(TRANSMISSAO.DIAGNOSTICODI);

  const canGenerateDIReg = useHasPermission(GERARXML.REGISTRODI);
  const canGenerateTransmissaoRegistroDI = useHasPermission(TRANSMISSAO.REGISTRODI);

  const genericDownloader = useGenericDownloader({
    autoDownload: true,
  });

  const calculoRelatedModel = useSelector(selectRelatedCalculoModel);
  const declaracoesImportacao = useSelector(selectDeclaracoesImportacao);
  const reportRelatedModel = useSelector(selectRelatedReportModel);
  const duplicataRelatedModel = useSelector(selectRelatedDuplicataModel);
  const multaRelatedModel = useSelector(selectRelatedMultaModel);
  const emissaoIcmsRelatedModel = useSelector(selectRelatedEmissaoIcmsModel);

  const mode = useSelector(selectMode);
  const isCalculate = useMemo(() => isCalculateMode(mode), [mode]);

  const configureDependencies = useCallback(() => {
    dispatch(clienteActions.changeControlTo(IDLE_CONTROL));
  }, []);

  const [isNovoRelatorio, setIsNovoRelatorio] = useState(false);

  const [handleImprimirClick, formDialogImpressaoStatus, handleFormDialogImpressaoStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(
          setRelatedReportModel({
            ...data,
            tipo: ReportUtils.DRAFT_DI_DUIMP,
          })
        );
      }
    },
    []
  );

  const isFormDialogImpressaoOpen = useMemo(() => isOpenStatus(formDialogImpressaoStatus), [formDialogImpressaoStatus]);

  const formDialogImpressaoSubmittingFeedback = useMemo(
    () =>
      t('com.muralis.qcx.gerandoRelatorioDIDUIMP', {
        processo: reportRelatedModel?.declaracaoImportacao?.processo || '#######',
      }),
    [reportRelatedModel]
  );

  const formDialogImpressaoContent = useMemo(
    () =>
      t('com.muralis.qcx.selecioneAbaixoTipoRelatorioGerarProcesso', {
        processo: reportRelatedModel?.declaracaoImportacao?.processo || '#######',
      }),
    [isFormDialogImpressaoOpen, reportRelatedModel]
  );

  const handleCloseFormDialogImpressao = useCallback(
    () => handleFormDialogImpressaoStatus(CLOSED_STATUS),
    [handleFormDialogImpressaoStatus]
  );

  const handleImprimirByIdSubmit = useCallback(
    (values) => {
      handleFormDialogImpressaoStatus(SUBMITTING_STATUS);

      const getTipoRelatorioLabel = (tipo) => {
        if (ReportUtils.isCalculoPorAdicao(tipo)) {
          return t('com.muralis.qcx.calculoAdicaoDIDUIMP');
        }
        if (ReportUtils.isDraftDiDuimp(tipo)) {
          return t('com.muralis.qcx.DIDUIMP.draftDeDIDUIMP');
        }
        if (ReportUtils.isItensPorOrdemDeDigitacao(tipo)) {
          return t('com.muralis.qcx.itensPorOrdemDigitacao');
        }
        return '';
      };

      const tipoRelatorioLabel = getTipoRelatorioLabel(values?.tipo);

      const fetcher = async () => {
        if (ReportUtils.isDraftDiDuimp(values?.tipo)) {
          return declaracaoImportacaoAPI.generateReportById(values?.declaracaoImportacao?.id);
        }

        if (ReportUtils.isCalculoPorAdicao(values?.tipo)) {
          return declaracaoImportacaoAPI.generateReportAdicoesById(values?.declaracaoImportacao?.id);
        }

        if (ReportUtils.isItensPorOrdemDeDigitacao(values?.tipo)) {
          return declaracaoImportacaoAPI.generateReportItensById(values?.declaracaoImportacao?.id);
        }

        return undefined;
      };

      const handlers = {
        onSuccess: () => {
          dispatch(resetRelatedReportModel());
          handleFormDialogImpressaoStatus(DONE_STATUS);
          const successMessage = t('com.muralis.qcx.mensagem.relatorioTipoGerado', {
            tipoRelatorio: tipoRelatorioLabel,
            processo: values?.declaracaoImportacao?.processo,
          });

          dispatch(
            setSuccessFeedback({
              message: successMessage,
            })
          );
        },
        onNoContent: () => {
          handleFormDialogImpressaoStatus(OPEN_STATUS);

          const infoMessage = t('com.muralis.qcx.mensagem.naoFoiPossivelGerarRelatorio', {
            tipoRelatorio: tipoRelatorioLabel,
            processo: values?.declaracaoImportacao?.processo,
          });

          dispatch(
            setErrorFeedback({
              message: infoMessage,
            })
          );
        },
        onError: async (reason) => {
          console.log(reason);
          handleFormDialogImpressaoStatus(FAIL_STATUS);

          let message = 'Erro desconhecido';
          try {
            if (reason[0]?.response?.data instanceof ArrayBuffer) {
              const decoder = new TextDecoder('utf-8');
              const text = decoder.decode(reason[0].response.data);
              const json = JSON.parse(text);
              message = json.message || message;
            } else {
              message = reason[0]?.response?.data?.message || 'Erro desconhecido';
            }
            if (message.startsWith('Erro ao gerar xml: ')) {
              message = message.replace('Erro ao gerar xml: ', '');
            }
          } catch (error) {
            console.error('Erro ao processar a mensagem de erro:', error);
          }

          const errorMessage = t('com.muralis.qcx.erro.erroGerarRelatorio', {
            tipoRelatorio: tipoRelatorioLabel,
            processo: values?.declaracaoImportacao?.processo,
            mensagem: message,
          });

          dispatch(
            setErrorFeedback({
              message: errorMessage,
            })
          );
        },
      };

      genericDownloader.downloadFileById(fetcher, handlers);
    },
    [handleFormDialogImpressaoStatus]
  );

  const handleConsultarClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        configureDependencies();

        dispatch(changeToConsultMode());

        history.push(t('com.muralis.qcx.url.importacaoDeclaracoesImportacaoDetalhes', { id: data?.id }));
      }
    },
    [configureDependencies, history]
  );

  const handleGerarXMLDiagnosticoById = useCallback((event, values) => {
    dispatch(
      generateReportDIDiagnosticoAsync({
        id: values?.declaracaoImportacao?.id,
        processo: values?.declaracaoImportacao?.processo,
      })
    );
  }, []);

  const handleGerarXMLRegistroById = useCallback((event, values) => {
    dispatch(
      generateReportDIRegistroAsync({
        id: values?.declaracaoImportacao?.id,
        processo: values?.declaracaoImportacao?.processo,
      })
    );
  }, []);

  const handleDiagnosticoCrawlerClick = useCallback(({ declaracaoImportacao }) => {
    handleOpenPopUpDiagnosticoDi({ args: { declaracaoImportacao } });
  });

  const [handleOpenPopUpDiagnosticoDi, diagnosticoDiPopUpConfig] = useConfigurePopUp({
    primaryConfirmCallback: async ({ declaracaoImportacao }) => {
      dispatch(diagnosticoDiByIdAsync({ declaracaoImportacao }));
    },
    initialOptions: {
      title: t('com.muralis.qcx.extracaoDiagnostico.titlePopUp'),
      message: t('com.muralis.qcx.transmissaoLi.messagePopUp'),
    },
  });

  const handleTransmissaoDuimp = ({ declaracaoImportacao }) => {
    dispatch(transmissaoDuimpByIdAsync({ declaracaoImportacao }));
  };

  const handleDiagnosticarDuimp = ({ declaracaoImportacao }) => {
    dispatch(transmissaoDiagnosticoDuimpByIdAsync({ declaracaoImportacao }));
  };

  const handleRegistraDuimp = ({ declaracaoImportacao }) => {
    dispatch(registrarDuimpByIdAsync({ declaracaoImportacao }));
  };

  const registrarDuimp = (values) => {
    if (modelDuimpSelecionada && modelDuimpSelecionada.id) {
      const { id, atributosAdicionais } = modelDuimpSelecionada;

      handleRegistraDuimp({
        declaracaoImportacao: {
          id,
          processo: atributosAdicionais?.processo,
          tipo: 'REGISTRO',
          transmitirComErros: values?.transmitirComErrosNaoImpeditivos === 'SIM',
        },
      });

      setExibirModalTransmissaoDuimp(false);
    }
  };

  const [handleCalcularClick, formDialogCalculoStatus, handleFormDialogCalculoStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(
          setRelatedCalculoModel({
            ids: data?.ids,
            id: data?.id,
            calculada: data?.calculada,
            atributosAdicionais: {
              tipo: data?.atributosAdicionais?.tipo,
              processo: data?.atributosAdicionais?.processo,
            },
            active: data?.active,
          })
        );
      }
    },
    []
  );

  const [handleOpenAliquotasDiferemPopUp, aliquotasDiferemPopUpConfig] = useConfigurePopUp({
    primaryConfirmCallback: (data) => dispatch(conferirByIdFromFaturaAsync({ ...data, ignoreAliquotas: true })),
    initialOptions: { title: 'Atenção as Alíquotas Diferem. Deseja continuar com a conferência?' },
  });

  const [handleConferirByIdClickMiddleware, operationOfConferirById] = useOperationMiddleware(async (data) => {
    dispatch(
      conferirByIdFromFaturaAsync({
        id: data?.id,
        onConflictCallback: handleOpenAliquotasDiferemPopUp,
      })
    );
  }, []);

  const handleConfigureConferirByIdClick = useCallback(
    (row, popupState) => {
      const { id, atributosAdicionais, metaFields } = row;

      const handleConferirByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoConferencia').toUpperCase(),
              message: t('com.muralis.qcx.confirmarOperacaoDIDUIMPConferida', {
                processo: atributosAdicionais?.processo,
              }),
              endMessage: t('com.muralis.qcx.certezaConferila'),
            },
          });

          const dataOfOperationConferir = {
            id,
            metaFields,
          };

          handleConferirByIdClickMiddleware(configureOperation, dataOfOperationConferir);
        }
      };

      return handleConferirByIdClick;
    },
    [handleConferirByIdClickMiddleware]
  );

  const [handleLiberarByIdClickMiddleware, operationOfLiberarById] = useOperationMiddleware(async (data) => {
    dispatch(
      liberarByIdFromFaturaAsync({
        id: data?.id,
      })
    );
  }, []);

  const [handleRetransmitirByIdClickMiddleware, operationOfRetransmitirById] = useOperationMiddleware(async (data) => {
    dispatch(
      retransmitirByIdFromFaturaAsync({
        id: data?.id,
      })
    );
  }, []);

  const handleConfigureLiberarByIdClick = useCallback(
    (row, popupState) => {
      const { id, atributosAdicionais, metaFields } = row;

      const handleLiberarByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.confirmarLiberacao').toUpperCase(),
              message: t('com.muralis.qcx.certezaLiberarRegistroDI', { processo: atributosAdicionais?.processo }),
            },
          });

          const dataOfOperationLiberar = {
            id,
            metaFields,
          };

          handleLiberarByIdClickMiddleware(configureOperation, dataOfOperationLiberar);
        }
      };

      return handleLiberarByIdClick;
    },
    [handleLiberarByIdClickMiddleware]
  );

  const handleConfigureRetransmitirByIdClick = useCallback(
    (row, popupState) => {
      const { id } = row;

      const handleRetransmitirByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: 'Confirmar operação de Retransmissão'.toUpperCase(),
              message: 'Deseja realmente retransmitir o registro DI?',
            },
          });

          const dataOfOperationRetransmitir = {
            id,
          };

          handleRetransmitirByIdClickMiddleware(configureOperation, dataOfOperationRetransmitir);
        }
      };

      return handleRetransmitirByIdClick;
    },
    [handleLiberarByIdClickMiddleware]
  );

  const [handleOpenPopUpReabrirDi, reabriDiPopUpConfig] = useConfigurePopUp({
    primaryConfirmCallback: async ({ id }) => {
      dispatch(reabrirByIdAsync({ id }));
    },
    initialOptions: {
      title: 'Deseja reabrir a DI?',
    },
  });

  const handleReabrirClick = useCallback(({ id }) => {
    handleOpenPopUpReabrirDi({ args: { id } });
  });

  const [
    handleDadosRegistroSiscomexClick,
    formDialogDadosRegistroSiscomexStatus,
    handleFormDialogDadosRegistroSiscomexStatus,
  ] = useFormDialogSync(async (event, data) => {
    if (isFunction(event?.stopPropagation)) {
      event.stopPropagation();

      dispatch(
        setRelatedRegistroModel({
          id: data?.id,
          processo: data?.processo,
          registro: data?.registro,
          dataRegistroSiscomex: data?.dataRegistroSiscomex,
          numeroTransmissao: data?.numeroTransmissao,
          confirmacaoPendente: !data?.registro || !data?.dataRegistroSiscomex,
        })
      );
    }
  }, []);

  const filterAvailableFollowUp = useCallback(async () => {
    const availableFollowUpsResponse = await followUpAPI
      .fetchByFilter([
        {
          name: 'servicoProduto',
          value: TipoProdutoUtils.IMPORTACAO,
        },
        {
          name: 'filtrarVinculados',
          value: true,
        },
      ])
      .catch((reason) => {
        const errorMessage = t('com.muralis.qcx.erro.erroConsultarFollowUp', {
          dado: reason?.response && reason?.response?.data ? `: ${reason?.response?.data?.message}.` : '.',
        });
        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      });

    if (availableFollowUpsResponse?.status === 200) {
      const availableFollowUps = availableFollowUpsResponse?.data;

      if (_.isArrayLikeObject(availableFollowUps) && _.isEmpty(availableFollowUps)) {
        dispatch(failure());

        dispatch(
          setErrorFeedback({
            message: t('com.muralis.qcx.validacao.naoEncontradoFollowUp'),
          })
        );

        return;
      }

      dispatch(followUpActions.resetControl());
      dispatch(
        followUpActions.setList({
          data: availableFollowUps,
        })
      );
    }
  }, []);

  const [handleDuplicarClick, formDialogDuplicacaoStatus, handleFormDialogDuplicacaoStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();
        await filterAvailableFollowUp();
        dispatch(
          setRelatedDuplicataModel({
            id: data?.id,
            atributosAdicionais: {
              tipo: TIPO_DI_DUIMP,
            },
            meta: {
              from: {
                processo: data?.processo,
              },
            },
          })
        );
      }
    },
    [filterAvailableFollowUp]
  );

  const handleDuplicarByProcessoSubmit = useCallback(
    (values) => {
      dispatch(
        duplicarByIdFromFaturaAsync({
          id: values?.id,
          meta: {
            from: {
              processo: values?.meta?.from?.processo,
            },
          },
          payload: {
            followUp: {
              id: values?.followUp?.id,
            },
            atributosAdicionais: {
              tipo: values?.atributosAdicionais?.tipo,
            },
          },
        })
      );

      handleFormDialogDuplicacaoStatus(DONE_STATUS);
    },
    [handleFormDialogDuplicacaoStatus]
  );

  const handleCloseFormDialogDuplicacao = useCallback(
    () => handleFormDialogDuplicacaoStatus(CLOSED_STATUS),
    [handleFormDialogDuplicacaoStatus]
  );

  const isFormDialogDuplicacaoOpen = useMemo(() => isOpenStatus(formDialogDuplicacaoStatus));

  const formDialogDuplicacaoContent = useMemo(
    () =>
      t('com.muralis.qcx.mensagem.preenchaNumeroNovoProcessoDuplicarDIDUIMP', {
        processo: duplicataRelatedModel?.meta?.from?.processo,
      }),
    [duplicataRelatedModel]
  );

  const fetchAll = useCallback(() => {
    dispatch(
      fetchAllDecImportWithPaginationAsync([
        { name: 'page', value: 1 },
        { name: 'size', value: 10 },
      ])
    );
  }, []);

  const [handleIncluirMultaClick, formDialogIncluirMultaStatus, handleFormDialogIncluirMultaStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(setRelatedMultaModel({ id: data?.id, multa: data?.multa }));
      }
    },
    []
  );

  const isFormDialogIncluirMultaOpen = useMemo(() => isOpenStatus(formDialogIncluirMultaStatus));

  const handleCloseFormDialogIncluirMulta = useCallback(() => {
    handleFormDialogIncluirMultaStatus(CLOSED_STATUS);
    _.debounce(() => {
      dispatch(resetRelatedMultaModel());
    }, 300);
  }, [handleFormDialogIncluirMultaStatus]);

  const handleIncluirMultaSubmit = useCallback(
    async (data) => {
      handleFormDialogIncluirMultaStatus(AWAITING_STATUS);

      try {
        dispatch(loading());
        handleFormDialogIncluirMultaStatus(SUBMITTING_STATUS);

        const updated = {
          id: data?.id,
          atributosAdicionais: {
            tipo: TIPO_DI_DUIMP,
            multa: {
              valorMultaReais: normalizeNumeral(data?.multa?.valorMultaReais),
              tipoRateioMulta: data?.multa?.tipoRateioMulta,
            },
          },
        };

        const response = await declaracaoImportacaoAPI.save(updated);

        if (response?.status === 200) {
          handleFormDialogIncluirMultaStatus(DONE_STATUS);
          dispatch(resetRelatedMultaModel());
          fetchAll();

          const savedMessage = 'Multa incluída no processo.';

          dispatch(success());
          dispatch(
            setSuccessFeedback({
              message: savedMessage,
            })
          );
        }
      } catch (error) {
        dispatch(failure());
        handleFormDialogIncluirMultaStatus(FAIL_STATUS);
        dispatch(
          setErrorFeedback({
            message: 'Erro ao incluir multa no processo.',
          })
        );
      }
    },
    [handleFormDialogIncluirMultaStatus]
  );

  const [handleEmissaoIcmsClick, formDialogEmissaoIcmsStatus, handleFormDialogEmissaoIcmsStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(
          setRelatedEmissaoIcmsModel({
            id: data?.id,
            processo: data?.processo,
            registro: data?.registro,
            dataVencimentoIcms: data?.dataVencimentoIcms,
            numeroConvenioProtocoloEspecificacaoMercadoria: data?.numeroConvenioProtocoloEspecificacaoMercadoria,
            confirmacaoPendente: !data?.dataVencimentoIcms,
            geracaoGareEmissaoIcms: data?.geracaoGareEmissaoIcms,
          })
        );
      }
    },
    []
  );

  const isFormDialogEmissaoIcmsOpen = useMemo(() => isOpenStatus(formDialogEmissaoIcmsStatus));

  const formDialogEmissaoIcmsContent = useMemo(
    () =>
      emissaoIcmsRelatedModel?.confirmacaoPendente || !emissaoIcmsRelatedModel?.dataVencimentoIcms
        ? t('com.muralis.qcx.mensagem.dataRelacionadaVencimentoPagamentoICMS')
        : t('com.muralis.qcx.mensagem.dataVencimentoPagamentoICMSSEFAZ'),
    [emissaoIcmsRelatedModel]
  );

  const handleCloseFormDialogEmissaoIcms = useCallback(() => {
    handleFormDialogEmissaoIcmsStatus(CLOSED_STATUS);
    _.debounce(() => {
      dispatch(resetRelatedEmissaoIcmsModel());
    }, 300);
  }, [handleFormDialogEmissaoIcmsStatus]);

  const [handleEmissaoIcmsByIdClickMiddleware, operationOfEmissaoIcms] = useOperationMiddleware(
    async (data) => {
      try {
        dispatch(loading());
        handleFormDialogEmissaoIcmsStatus(SUBMITTING_STATUS);

        const updated = {
          id: data?.id,
          atributosAdicionais: {
            tipo: TIPO_DI_DUIMP,
            dataVencimentoIcms: normalizeData(data?.dataVencimentoIcms),
            numeroConvenioProtocoloEspecificacaoMercadoria: data?.numeroConvenioProtocoloEspecificacaoMercadoria,
          },
        };

        const glmeSpPayload = {
          id: data?.id,
        };

        const icmsRioPayload = {
          id: data?.id,
          dataPagamento: normalizeData(data?.dataVencimentoIcms, 'DD/MM/YYYY'),
        };

        const response = await declaracaoImportacaoAPI.save(updated);
        const glmeSpResponse = await declaracaoImportacaoAPI.emitirGlmeSp(glmeSpPayload);
        const icmsRioResponse = await declaracaoImportacaoAPI.emitirIcmsRio(icmsRioPayload);

        if (glmeSpResponse?.status !== 200) {
          dispatch(
            setErrorFeedback({
              message: 'Erro ao notificar emissão de GLME SP',
            })
          );
        }

        if (response?.status === 200 && icmsRioResponse?.status === 200) {
          handleFormDialogEmissaoIcmsStatus(DONE_STATUS);
          dispatch(resetRelatedEmissaoIcmsModel());
          fetchAll();

          const savedMessage = data?.numeroConvenioProtocoloEspecificacaoMercadoria
            ? t('com.muralis.qcx.mensagem.dataVencmentoEmissaoICMSDIDUIMPSalvos', { registro: data?.registro })
            : t('com.muralis.qcx.mensagem.dataVencimentoEmissaoICMSDIDUIMPSalva', { registro: data?.registro });

          dispatch(success());
          dispatch(
            setSuccessFeedback({
              message: savedMessage,
            })
          );
        }
      } catch (error) {
        dispatch(failure());
        handleFormDialogEmissaoIcmsStatus(FAIL_STATUS);
        let errorMessage = t('com.muralis.qcx.naoPossivelSalvarDadosEmissaoICMSDIDUIMP', { registro: data?.registro });
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.naoPossivelSalvarDadosEmissaoICMSDIDUIMPMensagem', {
            registro: data?.registro,
            erro: error?.response?.data?.message,
          });
        }

        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      }
    },
    [formDialogEmissaoIcmsStatus, handleFormDialogEmissaoIcmsStatus]
  );

  const handleConfigureEmissaoIcmsByIdClick = useCallback(
    (values) => {
      const formattedDate = formatDate(values?.dataVencimentoIcms);

      const hasNumeroConvenioProtocoloEspecificacaoMercadoria =
        !!values?.numeroConvenioProtocoloEspecificacaoMercadoria;

      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.DIDUIMP.autorizarOperacaoDIDUIMP', { numero: values?.registro }),
          message: hasNumeroConvenioProtocoloEspecificacaoMercadoria
            ? t('com.muralis.qcx.mensagem.confirmarDataVencimentoEspecificacaoMercadoria', {
                data: formattedDate,
                mercadoria: values?.numeroConvenioProtocoloEspecificacaoMercadoria,
              })
            : t('com.muralis.qcx.mensagem.confirmarDataVencimento', { data: formattedDate }),
          endMessage: hasNumeroConvenioProtocoloEspecificacaoMercadoria
            ? t('com.muralis.qcx.DIDUIMP.certezaUtilizalosEmissaoICMS')
            : t('com.muralis.qcx.DIDUIMP.certezaUtilizalaEmissaoICMS'),
        },
      });

      handleEmissaoIcmsByIdClickMiddleware(configureOperation, values);
    },
    [formDialogEmissaoIcmsStatus, handleFormDialogEmissaoIcmsStatus, handleEmissaoIcmsByIdClickMiddleware]
  );

  const handleEmissaoIcmsSubmit = useCallback(
    async (values) => {
      handleFormDialogEmissaoIcmsStatus(AWAITING_STATUS);

      dispatch(
        setRelatedEmissaoIcmsModel({
          ...values,
          confirmacaoPendente: true,
        })
      );
      handleConfigureEmissaoIcmsByIdClick(values);
    },
    [handleFormDialogEmissaoIcmsStatus, handleConfigureEmissaoIcmsByIdClick]
  );

  const handleCloseEmissaoIcmsOperation = useCallback(() => {
    const callback = () => {
      if (isAwaitingStatus(formDialogEmissaoIcmsStatus)) {
        handleFormDialogEmissaoIcmsStatus(OPEN_STATUS);
      }
    };

    operationOfEmissaoIcms?.reset(callback);
  }, [operationOfEmissaoIcms, formDialogEmissaoIcmsStatus, handleFormDialogEmissaoIcmsStatus]);

  const [handleActivateOrInactivateByIdClickMiddleware, operationOfActivateOrInactivateById] = useOperationMiddleware(
    async (data) => {
      const isInactivation = data?.active;

      if (isInactivation) {
        dispatch(inactivateByIdFromFaturaAsync(data?.id));

        return;
      }

      dispatch(activateByIdFromFaturaAsync(data?.id));
    },
    [declaracoesImportacao]
  );

  const handleConfigureActivateOrInactivateByIdClick = useCallback(
    (row, popupState) => {
      const { id, atributosAdicionais, active } = row;

      const handleActivateOrInactivateByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoExclusao').toUpperCase(),
              message: t('com.muralis.qcx.certezaExcluirRecuperarDI', {
                acao: active
                  ? t('com.muralis.qcx.acoes.excluir').toLowerCase()
                  : t('com.muralis.qcx.acoes.recuperar').toLowerCase(),
                processo: atributosAdicionais?.processo,
              }),
            },
          });

          const dataOfOperationActivateOrInactivate = {
            id,
            active,
          };

          handleActivateOrInactivateByIdClickMiddleware(configureOperation, dataOfOperationActivateOrInactivate);
        }
      };

      return handleActivateOrInactivateByIdClick;
    },
    [handleActivateOrInactivateByIdClickMiddleware]
  );

  const formDialogCalculoContent = useMemo(
    () =>
      t('com.muralis.qcx.mensagem.inserirDataCalcularRecalcularDIDUIMP', {
        acao: isNaoCalculadaStatus(calculoRelatedModel?.calculada)
          ? t('com.muralis.qcx.acoes.calcular').toLowerCase()
          : t('com.muralis.qcx.acoes.recalcular').toLowerCase(),
        processo: calculoRelatedModel?.atributosAdicionais?.processo,
      }),
    [calculoRelatedModel]
  );

  const isFormDialogCalculoOpen = useMemo(() => isOpenStatus(formDialogCalculoStatus));

  const handleCloseFormDialogCalculo = useCallback(
    () => handleFormDialogCalculoStatus(CLOSED_STATUS),
    [handleFormDialogCalculoStatus]
  );

  const handleErrorBulkOperation = useCallback((errors) => {
    // errors.forEach((error) => {
    //   dispatch(setErrorFeedback({ message: error }));
    // });
    dispatch(success());
  }, []);

  const handleSuccessBulkOperation = useCallback(() => {
    handleFormDialogCalculoStatus(DONE_STATUS);
    dispatch(resetMode());
    dispatch(success());
    // fetchAll();
  });

  const handleBulkCalculateSubmit = useCallback(
    (values) => {
      const data = values?.ids.map((id) => ({
        id,
        dataCalculo: normalizeData(values?.atributosAdicionais?.dataCalculo),
      }));

      handleFormDialogCalculoStatus(SUBMITTING_STATUS);
      dispatch(loadingDI());

      dispatch(
        calcularDisLoteAsync({
          data,
          successCallback: handleSuccessBulkOperation,
          errorCallback: handleErrorBulkOperation,
        })
      );
    },
    [handleFormDialogCalculoStatus, handleSuccessBulkOperation, handleErrorBulkOperation]
  );

  const handleCalcularByDataSubmit = useCallback(
    async (values) => {
      try {
        dispatch(loading());

        handleFormDialogCalculoStatus(SUBMITTING_STATUS);

        const payload = {
          atributosAdicionais: {
            tipo: values?.atributosAdicionais?.tipo,
            dataCalculo: normalizeData(values?.atributosAdicionais?.dataCalculo),
          },
        };

        const response = await faturaAPI.calculateById(values?.id, [DECLARACAO_IMPORTACAO_ENDPOINT], payload);

        if (response.status === 200) {
          const isNaoCalculada = isNaoCalculadaStatus(values?.calculada);
          const processo = values?.atributosAdicionais?.processo;
          const responseMessage = t('com.muralis.qcx.mensagem.DIEstado', {
            processo,
            estado: isNaoCalculada ? t('com.muralis.qcx.estado.calculada') : t('com.muralis.qcx.estado.recalculada'),
          });

          dispatch(success());
          dispatch(
            updateOnList({
              data: response?.data,
            })
          );
          dispatch(setSuccessFeedback({ message: responseMessage }));

          if (isNaoCalculada) {
            dispatch(
              changeLastHistoryModel({
                processo: values?.atributosAdicionais?.processo,
              })
            );
          }

          handleFormDialogCalculoStatus(DONE_STATUS);
        }
      } catch (error) {
        handleFormDialogCalculoStatus(FAIL_STATUS);

        const isUnknownError =
          isEmpty(error?.response) || isEmpty(error?.response?.data) || isEmpty(error?.response?.data?.message);

        const isNaoCalculada = isNaoCalculadaStatus(values?.calculada);
        const errorMessage = isUnknownError
          ? t('com.muralis.qcx.erro.erroCalcularRecalcular', {
              estado: isNaoCalculada ? t('com.muralis.qcx.acoes.calcular') : t('com.muralis.qcx.acoes.recalcular'),
            })
          : t('com.muralis.qcx.erro.erroCalcularRecalcularEspecifico', {
              estado: isNaoCalculada ? t('com.muralis.qcx.acoes.calcular') : t('com.muralis.qcx.acoes.recalcular'),
              mensagem: error?.response?.data?.message,
            });

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      }
    },
    [declaracoesImportacao, handleFormDialogCalculoStatus]
  );

  const handleAtualizarDadosCarga = useCallback((faturaId) => {
    dispatch(atualizaDadosCargaAsync(faturaId));
  }, []);

  const handleObterChavesDuimp = ({ declaracaoImportacao }) => {
    dispatch(obterChavesDuimpAsync({ declaracaoImportacao }));
  };

  return {
    handleConsultarClick,
    handleImprimirClick,
    handleFormDialogImpressaoStatus,
    handleGerarXMLDiagnosticoById,
    handleGerarXMLRegistroById,
    handleDiagnosticoCrawlerClick,
    formDialogImpressaoSubmittingFeedback,
    isFormDialogImpressaoOpen,
    handleCloseFormDialogImpressao,
    formDialogImpressaoContent,
    handleImprimirByIdSubmit,
    reportRelatedModel,
    canGenerateDIDiag,
    canGenerateTransmissaoDiagnosticoDI,
    diagnosticoDiPopUpConfig,
    canGenerateDIReg,
    canGenerateTransmissaoRegistroDI,
    handleTransmissaoDuimp,
    exibirModalTransmissaoDuimp,
    setExibirModalTransmissaoDuimp,
    modelDuimpSelecionada,
    setModelDuimpSelecionada,
    registrarDuimp,
    handleCalcularClick,
    formDialogCalculoStatus,
    handleFormDialogCalculoStatus,
    operationOfConferirById,
    handleConfigureConferirByIdClick,
    aliquotasDiferemPopUpConfig,
    handleConfigureLiberarByIdClick,
    handleConfigureRetransmitirByIdClick,
    reabriDiPopUpConfig,
    operationOfLiberarById,
    operationOfRetransmitirById,
    handleDadosRegistroSiscomexClick,
    formDialogDadosRegistroSiscomexStatus,
    handleFormDialogDadosRegistroSiscomexStatus,
    handleDuplicarClick,
    formDialogDuplicacaoStatus,
    handleFormDialogDuplicacaoStatus,
    handleDuplicarByProcessoSubmit,
    handleCloseFormDialogDuplicacao,
    isFormDialogDuplicacaoOpen,
    formDialogDuplicacaoContent,
    duplicataRelatedModel,
    filterAvailableFollowUp,
    handleIncluirMultaClick,
    formDialogIncluirMultaStatus,
    handleFormDialogIncluirMultaStatus,
    isFormDialogIncluirMultaOpen,
    multaRelatedModel,
    handleIncluirMultaSubmit,
    handleCloseFormDialogIncluirMulta,
    fetchAll,
    handleEmissaoIcmsClick,
    formDialogEmissaoIcmsStatus,
    handleFormDialogEmissaoIcmsStatus,
    isFormDialogEmissaoIcmsOpen,
    formDialogEmissaoIcmsContent,
    handleEmissaoIcmsSubmit,
    handleCloseFormDialogEmissaoIcms,
    handleCloseEmissaoIcmsOperation,
    operationOfEmissaoIcms,
    operationOfActivateOrInactivateById,
    handleConfigureActivateOrInactivateByIdClick,
    formDialogCalculoContent,
    handleBulkCalculateSubmit,
    isFormDialogCalculoOpen,
    handleCloseFormDialogCalculo,
    handleSuccessBulkOperation,
    handleCalcularByDataSubmit,
    isCalculate,
    handleAtualizarDadosCarga,
    handleObterChavesDuimp,
  };
};

export default useDeclaracaoImportacaoActionsMenu;
