import { validateQueryParams } from '../../utils/api/api-utils';

import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import { EXPORTADOR_FABRICANTE_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

export async function fetchPaginate(params = []) {
  const validatedQueryParams = validateQueryParams(params);
  return doGetQCXRequest([EXPORTADOR_FABRICANTE_ENDPOINT, 'filter/pagination'], validatedQueryParams);
}

export async function fetchAll() {
  return doGetQCXRequest([EXPORTADOR_FABRICANTE_ENDPOINT]);
}

export async function fetchFilteredByName(queryParams) {
  return doGetQCXRequest([EXPORTADOR_FABRICANTE_ENDPOINT, 'filter/search'], [queryParams]);
}

export async function fetchById(id) {
  return doGetQCXRequest([EXPORTADOR_FABRICANTE_ENDPOINT, id]);
}

export async function register(data) {
  return doPostQCXRequest([EXPORTADOR_FABRICANTE_ENDPOINT], data);
}

export async function save(data) {
  return doPutQCXRequest([EXPORTADOR_FABRICANTE_ENDPOINT], data);
}

export async function activateById(id) {
  return doPutQCXActivateRequest([EXPORTADOR_FABRICANTE_ENDPOINT], id);
}

export async function inactivateById(id) {
  return doPutQCXInactivateRequest([EXPORTADOR_FABRICANTE_ENDPOINT], id);
}

export async function fetchPaisById(id) {
  return doGetQCXRequest([EXPORTADOR_FABRICANTE_ENDPOINT, 'find-pais', id]);
}
