import { ReponseType, validateQueryParams } from '../../utils/api/api-utils';
import { doGetQCXRequest, doPostQCXRequest, doPutQCXRequest } from '../../utils/api/domain/domain-api-utils';
import {
  ADICAO_ENDPOINT,
  CALCULO_ENDPOINT,
  DECLARACAO_IMPORTACAO_ENDPOINT,
  DI_ENDPOINT,
  FATURA_ENDPOINT,
  REPORT_ENDPOINT,
  ITENS_ENDPOINT,
  EMISSAO_ICMS_ENDPOINT,
  EMISSAO_GLME_SP_ENDPOINT,
  FILTER_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function save(data, queryParams = []) {
  return doPutQCXRequest([FATURA_ENDPOINT, data?.id, DECLARACAO_IMPORTACAO_ENDPOINT], data, queryParams);
}

async function fetchById(id) {
  return doGetQCXRequest([FATURA_ENDPOINT, id]);
}

async function generateReportById(id) {
  return doGetQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, REPORT_ENDPOINT, DI_ENDPOINT, id], [], {
    responseType: ReponseType.ARRAY_BUFFER,
  });
}

async function emitirIcmsRio(data) {
  return doGetQCXRequest(
    [DECLARACAO_IMPORTACAO_ENDPOINT, EMISSAO_ICMS_ENDPOINT, data.id],
    [
      {
        name: 'dataPagamento',
        value: data.dataPagamento,
      },
    ]
  );
}

async function emitirGlmeSp(data) {
  return doPostQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, EMISSAO_GLME_SP_ENDPOINT, data.id]);
}

async function generateReportAdicoesById(id) {
  return doGetQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, REPORT_ENDPOINT, CALCULO_ENDPOINT, ADICAO_ENDPOINT, id], [], {
    responseType: ReponseType.ARRAY_BUFFER,
  });
}

async function generateReportItensById(id) {
  return doGetQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, REPORT_ENDPOINT, ITENS_ENDPOINT, id], [], {
    responseType: ReponseType.ARRAY_BUFFER,
  });
}

async function calcularDisLote(data) {
  return doPutQCXRequest([FATURA_ENDPOINT, 'calcular', 'diduimp', 'lote'], data);
}

async function gerarXMLDiagnosticoLote(data, tipo) {
  const queryParams = [
    {
      name: 'tipo',
      value: tipo,
    },
  ];

  return doPutQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, 'xml-report', 'lotes'], data, queryParams, {
    responseType: 'blob',
  });
}

async function releaseDisEmLote(data) {
  return doPutQCXRequest([FATURA_ENDPOINT, 'conferir', 'lote'], data);
}

async function liberarDisEmLote(data) {
  return doPutQCXRequest([FATURA_ENDPOINT, 'liberar', 'lote'], data);
}

async function retransmitirDisEmLote(data) {
  return doPutQCXRequest([FATURA_ENDPOINT, 'retransmitir', 'lote'], data);
}

async function repassaImpostosComissaria(id) {
  return doPutQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, 'repassar-impostos-comissaria', id]);
}

async function fetchAdicoesByDiDuimpId(id) {
  return doGetQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, 'adicoes', id]);
}

async function updateMercadoria(idDIDUIMP, data) {
  return doPutQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, 'mercadoria', idDIDUIMP], data);
}

async function updateAdicao(data) {
  return doPutQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, 'adicao'], data);
}

async function fetchByFilterPaginate(params = []) {
  const validatedQueryParams = validateQueryParams(params);

  if (validatedQueryParams.length) {
    return doGetQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, FILTER_ENDPOINT, 'pagination'], validatedQueryParams);
  }
  return {
    data: {
      itens: [],
      totalNumberOfItens: 0,
    },
  };
}

async function fetchByDuimpsByIdFatura(params = []) {
  const validatedQueryParams = validateQueryParams(params);

  if (validatedQueryParams.length) {
    return doGetQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, FILTER_ENDPOINT, 'duimp-id'], validatedQueryParams);
  }
  return {
    data: {
      itens: [],
      totalNumberOfItens: 0,
    },
  };
}
async function fetchProcessoCusteio(params = []) {
  return doGetQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, 'processo-custeio'], params);
}

async function syncAdicoes(faturaId, data) {
  return doPostQCXRequest([DECLARACAO_IMPORTACAO_ENDPOINT, 'sync-adicoes', faturaId], data);
}

async function atualizaDadosCarga(faturaId) {
  return doGetQCXRequest(['crawler', 'consulta', 'carga', 'siscomex'], [{ name: 'faturaId', value: faturaId }]);
}

async function fetchPlanilhaAtributosItensDuimpAsync(id, params = []) {
  return doGetQCXRequest(['diduimp', 'planilha-atributos-itens', id], [], {
    responseType: 'blob',
  });
}

async function fetchImportarAtributosItensDuimpAsync(data) {
  return doPostQCXRequest(['diduimp', 'importacao-atributos-itens'], data);
}

async function fetchMercadoriasPaginated(id, params) {
  return doGetQCXRequest(['diduimp', 'mercadorias', 'paginated', id], params);
}

async function fetchMercadoriaById(id) {
  return doGetQCXRequest(['mercadoria', id], []);
}

async function fetchAdicoesIdsByDiDuimpId(id) {
  return doGetQCXRequest(['diduimp', 'adicoes-light', id], []);
}

async function fetchAdicaoById(id) {
  return doGetQCXRequest(['diduimp', 'adicao-by-id', id], []);
}

async function updateAdicaoAuxiliar(body) {
  return doPutQCXRequest(['diduimp', 'update-auxiliar'], body);
}

async function updateNvesRepasse(body) {
  return doPutQCXRequest(['diduimp', 'update-nves'], body);
}

async function findMercadoriasForNveRepasse(id) {
  return doGetQCXRequest(['diduimp', 'mercadorias-for-nve-repasse', id], []);
}

const declaracaoImportacaoAPI = {
  save,
  fetchById,
  generateReportById,
  generateReportAdicoesById,
  generateReportItensById,
  emitirIcmsRio,
  emitirGlmeSp,
  gerarXMLDiagnosticoLote,
  calcularDisLote,
  releaseDisEmLote,
  retransmitirDisEmLote,
  liberarDisEmLote,
  repassaImpostosComissaria,
  fetchAdicoesByDiDuimpId,
  updateMercadoria,
  updateAdicao,
  fetchByFilterPaginate,
  fetchProcessoCusteio,
  syncAdicoes,
  atualizaDadosCarga,
  fetchByDuimpsByIdFatura,
  fetchPlanilhaAtributosItensDuimpAsync,
  fetchImportarAtributosItensDuimpAsync,
  fetchMercadoriasPaginated,
  fetchMercadoriaById,
  fetchAdicoesIdsByDiDuimpId,
  fetchAdicaoById,
  updateAdicaoAuxiliar,
  updateNvesRepasse,
  findMercadoriasForNveRepasse,
};

export {
  declaracaoImportacaoAPI,
  save,
  fetchById,
  generateReportById,
  generateReportAdicoesById,
  generateReportItensById,
  emitirIcmsRio,
  emitirGlmeSp,
  gerarXMLDiagnosticoLote,
  calcularDisLote,
  releaseDisEmLote,
  retransmitirDisEmLote,
  liberarDisEmLote,
  repassaImpostosComissaria,
  fetchAdicoesByDiDuimpId,
  updateMercadoria,
  updateAdicao,
  fetchByFilterPaginate,
  fetchProcessoCusteio,
  syncAdicoes,
  atualizaDadosCarga,
  fetchByDuimpsByIdFatura,
  fetchPlanilhaAtributosItensDuimpAsync,
  fetchImportarAtributosItensDuimpAsync,
  fetchMercadoriasPaginated,
  fetchMercadoriaById,
  fetchAdicoesIdsByDiDuimpId,
  fetchAdicaoById,
  updateAdicaoAuxiliar,
  updateNvesRepasse,
  findMercadoriasForNveRepasse,
};
