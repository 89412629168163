import { Tooltip } from '@material-ui/core';

const TableCellWithTooltip = ({ value, label = undefined }) => {
  if (!value) return <span>{label ?? value}</span>;

  return (
    <Tooltip title={value}>
      <div
        style={{
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <span>{label ?? value}</span>
      </div>
    </Tooltip>
  );
};

export default TableCellWithTooltip;
