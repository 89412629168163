import React, { useEffect, useMemo, useCallback } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { required as requiredValidator, isCurrentOrPastDate } from '../../utils/validators/field/validator';
import { danfeVolumesActions, danfeVolumesSelectors } from '../../features/danfe-volumes/danfeVolumesSlice';
import QCXSelectCFOPOperacoesFiscaisAutocomplete from '../../shared-components/final-autocomplete-select-cfop-operacoes-fiscais-field/QCXSelectCFOPOperacoesFiscaisAutocomplete';
import QCXSelectTransportadoraAutocomplete from '../../shared-components/select-transportadora/QCXSelectTransportadoraAutocomplete';
import { cfopOperacaoFiscalActions } from '../../features/cfop-operacoes-fiscais/cfopOperacoesFiscaisSlice';
import QCXControlBoxModeloInformacaoComplementar from '../../shared-components/modelo-informacao-complementar/QCXFinalControlBoxModeloInformacaoComplementar';
import { AUTO_LOAD_CONTROL } from '../../features/config-control';
import useValidators from '../../utils/hooks/useValidators';
import { selectTiposEmbalagens } from '../../features/tipo-embalagem-nacional/tipoEmbalagemNacionalSlice';
import { fetchById as fetchTipoEmbalagemById } from '../../features/tipo-embalagem-nacional/tipoEmbalagemNacionalAPI';
import QCXSelectFretePorContaAutocomplete from '../../shared-components/select-frete-por-conta/QCXSelectFretePorContaAutocomplete';
import QCXFinalDateTimePickerField from '../../shared-components/final-date-time-picker-field/QCXFinalDateTimePickerField';
import QCXFormTitle from '../form-title/QCXFormTitle';
import QCXSelectTipoEmbalagemAutocomplete from '../../shared-components/select-tipo-embalagem/QCXSelectTipoEmbalagemAutocomplete';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXFinalBondManagerOld from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import { setModel } from '../../features/danfe/danfeSlice';
import QCXSelectDeclaracaoImportacaoAutocomplete from '../../shared-components/select-declaracao-importacao/QCXSelectDeclaracaoImportacaoAutocomplete';
import { estadoActions } from '../../features/estado/estadoSlice';
import QCXSelectDanfeUfAutocomplete from '../../shared-components/select-danfe-uf/QCXSelectDanfeUfAutocomplete';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import { numberOnly, parseToUpperCaseOnly } from '../../utils/hooks/form/field/parsers';
import QCXInfoAlert from '../../shared-components/alert/QCXInfoAlert';
import { fetchById as fetchDiById } from '../../features/declaracao-importacao/declaracaoImportacaoAPI';
import QCXLink from '../link/QCXLink';
import { isStrictEquals } from '../../utils/general/general-utils';
import { isDanfeFilhote, hasFilhotes } from '../../utils/general/danfe/danfeUtils';
import QCXSelectMotivoDesoneracaoICMSAutocomplete from '../../shared-components/select-motivo-desoneracao-icms/QCXSelectMotivoDesoneracaoICMSAutocomplete';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXSelectICMSDiferidoAutocomplete from '../../shared-components/select-icms-diferido/QCXSelectICMSDiferidoAutocomplete';
import { BRAZILIAN } from '../../utils/hooks/form/field/mask-types';
import QCXFinalPercentualField from '../../shared-components/final-percentual-field/QCXFinalPercentualField';
import QCXAloneInlineBoxWrapper from '../../shared-components/alone-inline-box-wrapper/QCXAloneInlineBoxWrapper';
import QCXControlBoxModeloInformacaoComplementarOld from '../../shared-components/modelo-informacao-complementar/QCXFinalControlBoxModeloInformacaoComplementarOld';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  customGridFields: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '16px',
    },
    paddingBottom: '16px',
  },
  informacoesComplementares: {
    fontFamily: 'monospace',
  },
}));

const GridContainer = ({ children }) => (
  <Grid container spacing={2} style={{ marginTop: '1rem' }}>
    {children}
  </Grid>
);

export default function QCXDanfeBasicasForm({
  isCreate,
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    dispatch(cfopOperacaoFiscalActions.changeControlTo(AUTO_LOAD_CONTROL));
    dispatch(estadoActions.changeControlTo(AUTO_LOAD_CONTROL));
  }, []);

  const isFilhote = useMemo(() => isDanfeFilhote(initialValues?.tipoDanfe), [initialValues]);

  const placaValidator = (value) => {
    if (typeof value !== 'string' || String(value).length <= 0) {
      // Outro validador deve tratar da obrigatoriedade.
      return undefined;
    }

    // Esta regex foi copiada da lib Java com.fincatto.documentofiscal pois esta
    // mesma validação vai ocorrer no backend. Guardaremos a placa sem traços
    // ou espaços.
    const matches = value
      .replace('-', '')
      .replace(' ', '')
      .match(/^([A-Z]{2,3}[0-9]{4}|[A-Z]{3,4}[0-9]{3}|[A-Z]{3}[0-9][A-Z][0-9]{2})$/);
    if (!matches) {
      return t('com.muralis.qcx.placaVeiculoNaoEstaPadrao');
    }

    return undefined;
  };

  const mandatoryNonFutureDateValidator = useValidators([requiredValidator, isCurrentOrPastDate]);

  const validadorDaPlaca = useValidators([placaValidator]);

  const tiposEmbalagemList = useSelector(selectTiposEmbalagens);

  const modeloInformacaoComplementarFieldProps = useMemo(
    () => ({
      meta: {
        visible: {
          informacoesComplementaresCompleta: isConsult,
        },
      },
      modeloInformacaoComplementar: {
        name: 'modeloInformacaoComplementar.id',
        label: t('com.muralis.qcx.modeloInformacaoComplementar.label'),
      },
      informacoesComplementares: {
        name: 'infoAdicional',
        label: t('com.muralis.qcx.importador.informacoesComplementares'),
        maxLength: 5000,
        rows: 9,
        inputProps: {
          className: classes.informacoesComplementares,
        },
        helperText: t('com.muralis.qcx.mensagem.dadosReferenciaModeloInfoComplementarDANFE'),
      },
      informacoesComplementaresCompleta: {
        name: 'infoAdicionalCompleta',
        label: t('com.muralis.qcx.informacoesComplementaresInteresseFisco'),
        maxLength: 5000,
        rows: 9,
        inputProps: {
          className: classes.informacoesComplementares,
        },
        helperText: t('com.muralis.qcx.mensagem.dadosGeradosReferenciaModeloInformacoesComplementares'),
      },
    }),
    [initialValues, isConsult]
  );

  const controlButtonsGridProps = useMemo(
    () => ({
      add: {
        xs: 12,
        sm: 4,
        md: 4,
      },
      update: {
        xs: 12,
        sm: 2,
        md: 2,
      },
      clear: {
        xs: 6,
        sm: 2,
        md: 2,
      },
    }),
    []
  );

  const embalagensListProps = useMemo(
    () => ({
      name: 'volumes',
      columns: [
        {
          field: 'embalagem',
          headerName: t('com.muralis.qcx.embalagem.labelSingular'),
          headerAlign: 'center',
          align: 'center',
          flex: 180,
          valueGetter: ({ row }) => row?.tipoEmbalagem?.description || '-',
        },
        {
          field: 'quantidade',
          headerName: t('com.muralis.qcx.quantidade.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 180,
          valueGetter: ({ row }) => row?.quantidade || '-',
        },
        {
          field: 'marcacao',
          headerName: t('com.muralis.qcx.marcacao'),
          headerAlign: 'center',
          align: 'center',
          flex: 200,
          valueGetter: ({ row }) => row?.marcacao || '-',
        },
        {
          field: 'numeracao',
          headerName: t('com.muralis.qcx.numeracao'),
          headerAlign: 'center',
          align: 'center',
          flex: 140,
          valueGetter: ({ row }) => row?.numeracao || '-',
        },
      ],
    }),
    []
  );

  const embalagensFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.emb',
      fields: [
        {
          name: 'tipoEmbalagem.id',
          label: t('com.muralis.qcx.embalagem.labelSingular'),
        },
        {
          name: 'quantidade',
          label: t('com.muralis.qcx.quantidade.label'),
        },
        {
          name: 'marcacao',
          label: t('com.muralis.qcx.numeracao'),
          required: false,
        },
        {
          name: 'numeracao',
          label: t('com.muralis.qcx.numeracao'),
          required: false,
        },
      ],
    }),
    []
  );

  const embalagensReducerConfig = useMemo(
    () => ({
      selectors: danfeVolumesSelectors,
      actions: danfeVolumesActions,
    }),
    []
  );

  const handleAddEmbalagem = useCallback(
    async (currentItem, currentCollection, handleSuccess) => {
      currentItem.id = uuid();
      currentItem.tipoEmbalagem =
        tiposEmbalagemList?.find((el) => el?.id === currentItem?.tipoEmbalagem?.id) || currentItem?.tipoEmbalagem;
      const newCollection = [...currentCollection, currentItem];
      // Aqui não podemos atualizar somente os volumes, porque isso faria o resto
      // do formulário resetar.
      // dispatch(updateModelVolumes(newCollection));
      // Ainda há algo de errado aqui, quando setamos os valores desse jeito, os
      // outros campos somem ao passar para a próxima página.

      handleSuccess(newCollection);
    },
    [tiposEmbalagemList]
  );

  const handleUpdateEmbalagem = useCallback(async (currentItem, currentCollection, handleSuccess, values) => {
    const tipoEmbalagemResponse = await fetchTipoEmbalagemById(currentItem?.tipoEmbalagem?.id);

    if (tipoEmbalagemResponse?.status === 200) {
      const foundTipoEmbalagem = tipoEmbalagemResponse?.data;

      const updatedVinculo = {
        ...currentItem,
        tipoEmbalagem: foundTipoEmbalagem,
      };

      const updatedCollection = currentCollection?.map((it) =>
        isStrictEquals(it?.id, updatedVinculo?.id) ? updatedVinculo : it
      );

      dispatch(
        setModel({
          ...values,
          volumes: updatedCollection,
        })
      );

      handleSuccess(updatedCollection);
    }
  }, []);

  const currentDate = moment().toDate();

  const model = useMemo(
    () =>
      isCreate
        ? {
            ...initialValues,
            dataEntrada: isFilhote ? initialValues?.dataEntrada : currentDate,
            dataEmissao: isFilhote ? initialValues?.dataEmissao : currentDate,
          }
        : initialValues,
    [initialValues, currentDate, isCreate, isFilhote]
  );

  const placaVeiculoNaoPreenchida = useCallback((values) => !values?.veiculoPlaca, []);

  const ufVeiculoNaoPreenchida = useCallback((values) => !values?.veiculoUf, []);

  const handlePlacaVeiculoChange = useCallback(
    (form, values) => (value) => {
      if (isEmpty(value) && ufVeiculoNaoPreenchida(values)) {
        const newValue = values?.veiculoUf === undefined ? null : undefined;

        form.change('veiculoUf', newValue);
      }
    },
    [ufVeiculoNaoPreenchida]
  );

  const handleUfVeiculoChange = useCallback(
    (form, values) => (value) => {
      if (isEmpty(value) && placaVeiculoNaoPreenchida(values)) {
        form.change('veiculoUf', undefined);
        form.resetFieldState('veiculoUf');
      }
    },
    [placaVeiculoNaoPreenchida]
  );

  const handleDiChange = useCallback(
    (form) => async (value) => {
      if (!isConsult && value && !isFilhote) {
        const responseDiDuimp = await fetchDiById(value);

        if (responseDiDuimp?.status === 200 && !isEmpty(responseDiDuimp) && responseDiDuimp?.data) {
          const diDuimp = responseDiDuimp?.data;
          const adicoes = diDuimp?.atributosAdicionais?.adicoes || [];

          const embalagens = diDuimp?.atributosAdicionais?.carga?.quantidades || [];
          const embalagensUnnormalizeds = embalagens.map((e) => ({ ...e, tipoEmbalagem: e.tipoEmbalagemNacional }));
          form.change('volumes', embalagensUnnormalizeds);

          const idsOperacoesFiscais = adicoes?.map((adicao) => adicao?.operacaoFiscal?.id);

          const operacoesFiscais = idsOperacoesFiscais?.filter((valor, index, self) => self.indexOf(valor) === index);

          const modeloDanfe = diDuimp?.importador?.modeloDanfe;
          form.change('ignorableFields.modeloDanfe', modeloDanfe);

          if (operacoesFiscais?.length === 1) {
            form.change('cfop.id', operacoesFiscais[0]);
            return;
          }

          form.change('cfop.id', undefined);
        }
      }
    },
    [isConsult, isFilhote]
  );

  const handleCFOPChange = useCallback(
    (form, values) => async (value) => {
      if (!isConsult && value && !isFilhote) {
        const modeloDanfe = values?.ignorableFields?.modeloDanfe;
        const cstWithSameCFOP = modeloDanfe?.cstList?.find((cst) => cst?.cfop?.id === value);

        if (!cstWithSameCFOP) {
          return;
        }

        form.change('cst', cstWithSameCFOP.cst);
        form.change('cstPis', cstWithSameCFOP.cstPis);
        form.change('cstCofins', cstWithSameCFOP.cstCofins);
        form.change('cstIpi', cstWithSameCFOP.cstIpi);
      }
    },
    [isConsult, isFilhote]
  );

  const onChangeListeners = useMemo(
    () => [
      {
        name: 'veiculoPlaca',
        fn: handlePlacaVeiculoChange,
      },
      {
        name: 'di.id',
        fn: handleDiChange,
      },
      {
        name: 'veiculoUf',
        fn: handleUfVeiculoChange,
      },
      { name: 'cfop.id', fn: handleCFOPChange },
    ],
    [handlePlacaVeiculoChange, handleDiChange, handleUfVeiculoChange]
  );

  // Embalagens novas sempre são permitidas.
  const handleEmbalagemAlreadyExists = useCallback(() => false, []);

  return (
    <QCXFormStepWizard
      isConsult={isConsult}
      isUpdate={isUpdate}
      disablePristine
      initialValues={model}
      handleSubmitValues={handleSubmit}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      onChangeListeners={onChangeListeners}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      {...restProps}
    >
      {({ values }) => (
        <>
          <GridContainer>
            {isCreate && !isFilhote && (
              <Grid
                item
                xs={12}
                style={{
                  marginTop: '0.5rem',
                  paddingBottom: '!important 0px',
                }}
              >
                <QCXInfoAlert>
                  <Trans i18nKey="com.muralis.qcx.mensagem.DANFEInformacoes">
                    <ul>
                      <li>
                        <QCXLink to={t('com.muralis.qcx.url.moduloOperacionaisClientes')}>
                          <strong>
                            {{
                              categoriaCliente: t('com.muralis.qcx.caminho.OperacionaisClientes').toUpperCase(),
                            }}
                          </strong>
                        </QCXLink>
                        <strong>
                          {{
                            aba: t('com.muralis.qcx.dadosTecnicos'),
                          }}
                        </strong>
                      </li>
                      <li>
                        <QCXLink
                          to={t('com.muralis.qcx.url.moduloOperacionaisModeloDANFE')}
                          style={{ color: '#f39200' }}
                        >
                          <strong>
                            {{
                              categoriaDANFE: t('com.muralis.qcx.caminho.OperacionaisModeloDANFE').toUpperCase(),
                            }}
                          </strong>
                        </QCXLink>
                      </li>
                      <li>
                        <strong>
                          {{
                            status: t('com.muralis.qcx.registrada').toUpperCase(),
                          }}
                        </strong>
                      </li>
                      <li>
                        <QCXLink to={t('com.muralis.qcx.url.moduloOperacionaisTransportadoras')}>
                          <strong>
                            {{
                              categoriaTransportadora: t(
                                'com.muralis.qcx.caminho.OperacionaisTransportadora'
                              ).toUpperCase(),
                            }}
                          </strong>
                        </QCXLink>
                        <strong>
                          {{
                            estadoNovo: t('com.muralis.qcx.acoes.novo'),
                          }}
                        </strong>
                      </li>
                    </ul>
                  </Trans>
                </QCXInfoAlert>
              </Grid>
            )}
            {isConsult && hasFilhotes(values) && (
              <Grid
                item
                xs={12}
                style={{
                  marginTop: '0.5rem',
                  paddingBottom: '!important 0px',
                }}
              >
                <QCXInfoAlert>
                  <Trans i18nKey="com.muralis.qcx.mensagem.DANFEMaeInformacoes" />
                </QCXInfoAlert>
              </Grid>
            )}
            {isConsult && isFilhote && (
              <Grid
                item
                xs={12}
                style={{
                  marginTop: '0.5rem',
                  paddingBottom: '!important 0px',
                }}
              >
                <QCXInfoAlert>
                  <Trans i18nKey="com.muralis.qcx.mensagem.DANFEFilhoteInformacoes">
                    <strong>
                      {{
                        tipoDanfe: t('com.muralis.qcx.filhote').toLowerCase(),
                      }}
                    </strong>
                  </Trans>
                </QCXInfoAlert>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              style={{
                paddingTop: '0px',
              }}
            >
              <QCXFormTitle title={t('com.muralis.qcx.fichaBasicas')} />
            </Grid>
            <QCXAloneInlineBoxWrapper>
              {(fieldProps) => (
                <QCXSelectDeclaracaoImportacaoAutocomplete
                  id="select-di"
                  key="select-di"
                  name="di.id"
                  label="Processo"
                  initialValues={values}
                  validate={requiredValidator}
                  disabled={isConsult || (isCreate && isFilhote)}
                  required
                  {...fieldProps}
                />
              )}
            </QCXAloneInlineBoxWrapper>
            <Grid item xs={12} sm={6} md={6}>
              <QCXSelectCFOPOperacoesFiscaisAutocomplete
                id="select-cfop"
                key="select-cfop"
                name="cfop"
                label={t('com.muralis.qcx.CFOP.label')}
                disabled={isConsult || (isCreate && isFilhote)}
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalDateTimePickerField
                id="date-emissao"
                key="date-emissao"
                name="dataEmissao"
                label={t('com.muralis.qcx.dataEmissao')}
                disableFuture
                disabled={isConsult || (isCreate && isFilhote)}
                validate={mandatoryNonFutureDateValidator}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalDateTimePickerField
                id="date-entrada"
                key="date-entrada"
                name="dataEntrada"
                label={t('com.muralis.qcx.dataEntrada')}
                disableFuture
                disabled={isConsult || (isCreate && isFilhote)}
                validate={mandatoryNonFutureDateValidator}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <QCXSelectTransportadoraAutocomplete
                id="transportador"
                key="transportador"
                name="transportador"
                label={t('com.muralis.qcx.transportador.label')}
                disabled={isConsult || (isCreate && isFilhote)}
                validate={requiredValidator}
                initialValues={values}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <QCXSelectFretePorContaAutocomplete
                id="frete-por-conta"
                key="frete-por-conta"
                name="frete"
                label={t('com.muralis.qcx.frete.freteConta')}
                disabled={isConsult || (isCreate && isFilhote)}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <QCXFinalTextField
                id="placa-veiculo"
                key="placa-veiculo"
                name="veiculoPlaca"
                label={t('com.muralis.qcx.placaVeiculo')}
                disabled={isConsult}
                validate={validadorDaPlaca}
                maxLength={8}
                parse={parseToUpperCaseOnly}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <QCXSelectDanfeUfAutocomplete
                id="select-veiculo-uf"
                key="select-veiculo-uf"
                name="veiculoUf"
                label={t('com.muralis.qcx.UFVeiculo')}
                initialValues={values}
                disabled={isConsult}
                {...(values?.veiculoPlaca
                  ? {
                      fieldProps: {
                        validate: requiredValidator,
                      },
                      required: true,
                    }
                  : {})}
              />
            </Grid>
          </GridContainer>
          <GridContainer>
            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.ICMS.ICMSPISCOFINSIPI')} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalNumericIntegerField
                id="text-field-cst-icms"
                key="text-field-cst-icms"
                name="cst"
                label={t('com.muralis.qcx.ICMS.CSTICMS')}
                disabled={isConsult || (isCreate && isFilhote)}
                allowNegative={false}
                maxLength={3}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectMotivoDesoneracaoICMSAutocomplete
                id="select-field-motivo-desoneracao-icms"
                key="select-field-motivo-desoneracao-icms"
                name="motivoDesoneracaoIcms"
                label={t('com.muralis.qcx.ICMS.motivoDesoneracaoICMS')}
                initialValues={values}
                disabled={isConsult || (isCreate && isFilhote)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalNumericIntegerField
                id="text-field-cst-pis"
                key="text-field-cst-pis"
                name="cstPis"
                label={t('com.muralis.qcx.CST.CSTPIS')}
                disabled={isConsult || (isCreate && isFilhote)}
                allowNegative={false}
                maxLength={2}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalNumericIntegerField
                id="text-field-cst-cofins"
                key="text-field-cst-cofins"
                name="cstCofins"
                label={t('com.muralis.qcx.CST.CSTCOFINS')}
                disabled={isConsult || (isCreate && isFilhote)}
                allowNegative={false}
                maxLength={2}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalNumericIntegerField
                id="text-field-cst-ipi"
                key="text-field-cst-ipi"
                name="cstIpi"
                label={t('com.muralis.qcx.CST.CSTIPI')}
                disabled={isConsult || (isCreate && isFilhote)}
                allowNegative={false}
                maxLength={2}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalTextField
                id="text-field-codigo-enquadramento-legal-ipi"
                key="text-field-codigo-enquadramento-legal-ipi"
                name="enquadramentoLegalIpi"
                label={t('com.muralis.qcx.IPI.enquadramentoLegalIPI')}
                disabled={isConsult || (isCreate && isFilhote)}
                maxLength={3}
                parse={numberOnly}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalTextField
                id="text-field-codigo-selo-de-controle-ipi"
                key="text-field-codigo-selo-de-controle-ipi"
                name="codigoSeloControleIpi"
                label={t('com.muralis.qcx.IPI.codigoSeloControleIPI')}
                disabled={isConsult || (isCreate && isFilhote)}
                maxLength={50}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalNumericIntegerField
                id="text-field-quantidade-selo-de-controle-ipi"
                key="text-field-quantidade-selo-de-controle-ipi"
                name="quantidadeSeloControleIpi"
                label={t('com.muralis.qcx.IPI.QuantidadeSeloControleIPI')}
                disabled={isConsult || (isCreate && isFilhote)}
                allowNegative={false}
                maxLength={8}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectICMSDiferidoAutocomplete
                id="select-field-icms-diferido"
                key="select-field-icms-diferido"
                name="icmsDiferido"
                label={t('com.muralis.qcx.ICMS.ICMSDiferido')}
                initialValues={values}
                disabled={isConsult || (isCreate && isFilhote)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalPercentualField
                id="percentual-icms-diferido"
                key="percentual-icms-diferido"
                name="percentualIcmsDiferido"
                label={t('com.muralis.qcx.ICMS.ICMSDiferido')}
                disabled={isConsult || (isCreate && isFilhote)}
                scale={4}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalAlternativeCurrencyField
                id="currency-valor-icms-diferido"
                key="currency-valor-icms-diferido"
                name="valorIcmsDiferido"
                label={t('com.muralis.qcx.ICMS.valorICMSDiferido')}
                intl={BRAZILIAN}
                disabled={isConsult || (isCreate && isFilhote)}
                adorned
              />
            </Grid>
            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.dadosAdicionais')} />
            </Grid>
            <QCXControlBoxModeloInformacaoComplementarOld
              id="control-box-modelo-informacoes-complementares"
              key="control-box-modelo-informacoes-complementares"
              fieldProps={modeloInformacaoComplementarFieldProps}
              disabledFields={isConsult}
            />
            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.dadosEmbalagens')} />
            </Grid>
            <QCXFinalBondManagerOld
              isParentConsult={isConsult}
              listProps={embalagensListProps}
              formProps={embalagensFormProps}
              reducerConfig={embalagensReducerConfig}
              handleAdd={(currentItem, currentCollection, handleSuccess) =>
                handleAddEmbalagem(currentItem, currentCollection, handleSuccess)
              }
              handleUpdate={(currentItem, currentCollection, handleSuccess) =>
                handleUpdateEmbalagem(currentItem, currentCollection, handleSuccess, values)
              }
              handleAlreadyExists={handleEmbalagemAlreadyExists}
              controlButtonsGridProps={controlButtonsGridProps}
              disabled={isConsult}
            >
              <Grid item className={classes.gridFields} xs={12} sm={4} md={4}>
                <QCXSelectTipoEmbalagemAutocomplete
                  id="select-embalagem"
                  key="select-embalagem"
                  name="ignorableFields.emb.tipoEmbalagem.id"
                  label={t('com.muralis.qcx.embalagem.labelSingular')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item className={classes.gridFields} xs={12} sm={4} md={4}>
                <QCXFinalNumericIntegerField
                  id="embalagem-qtd"
                  key="embalagem-qtd"
                  name="ignorableFields.emb.quantidade"
                  label={t('com.muralis.qcx.quantidade.label')}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item className={classes.gridFields} xs={12} sm={4} md={4}>
                <QCXFinalTextField
                  id="embalagem-marcacao"
                  key="embalagem-marcacao"
                  name="ignorableFields.emb.marcacao"
                  label={t('com.muralis.qcx.marcacao')}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item className={classes.customGridFields} xs={12} sm={4} md={4}>
                <QCXFinalTextField
                  id="embalagem-numeracao"
                  key="embalagem-numeracao"
                  name="ignorableFields.emb.numeracao"
                  label={t('com.muralis.qcx.numeracao')}
                  disabled={isConsult}
                />
              </Grid>
            </QCXFinalBondManagerOld>
          </GridContainer>
        </>
      )}
    </QCXFormStepWizard>
  );
}
